const BUTTON_SUBMIT = {
  COLOR: 'orange',
  VARIANT: 'filled',
}

const BUTTON_CREATE = {
  COLOR: 'indigo',
  VARIANT: 'filled',
  CLASS_TEXT: 'font-normal',
  DEFAULT_TEXT: 'Crear',
}

const BUTTON_BACK = {
  COLOR: 'indigo',
  VARIANT: 'outlined',
  CLASS_TEXT: 'font-normal',
  DEFAULT_TEXT: 'Atras',
}

export {
  BUTTON_SUBMIT,
  BUTTON_CREATE,
  BUTTON_BACK,
}