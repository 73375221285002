import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";

export const DialogOfDeleteComponent = ({show, setShow, confirmCallBack, message, title}) => {
  const handleOpen = () => setShow(!show);

  const confirmAction = () => {
    confirmCallBack()
    handleOpen()
  }

  return (
    <>
      <Dialog open={show} handler={handleOpen} size="xs" dismiss={{outsidePress: false}}>
        <DialogBody className="grid place-items-center gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="black"
            className="mr-3 h-6 w-6 right-2 top-5 absolute cursor-pointer"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-16 w-16 text-red-500"
          >
            <path
              fillRule="evenodd"
              d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
              clipRule="evenodd"
            />
          </svg>
          <Typography color="red" variant="h4">
            {title? title: "¡Eliminar Registro!"}
          </Typography>
          <Typography className="text-center font-normal text-black">
            {message ? message : 'Estás a punto de eliminar un registro. Esta acción no se puede deshacer. ¿Estás seguro de que deseas continuar?'}
          </Typography>
        </DialogBody>
        <DialogFooter className="">
          <div className="w-full flex md:flex-row lg:flex-row flex-col justify-between gap-3">
            <Button
              type="button"
              variant="filled"
              color="gray"
              onClick={handleOpen}
              className="">
              Cancelar
            </Button>
            <Button variant="gradient" onClick={confirmAction} color="red" className="">
              Confirmar
            </Button>
          </div>

        </DialogFooter>
      </Dialog>
    </>
  )
}