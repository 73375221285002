import React, {useEffect, useState} from "react";
import {ErrorMessage, useField} from "formik";
import {Input, Popover, PopoverContent, PopoverHandler, Typography} from "@material-tailwind/react";
import {
  CalendarIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
  
} from "@heroicons/react/20/solid";
import {format} from "date-fns";
import esLocale from "date-fns/locale/es";
import {toastError} from '../../../js/toast'
import {DayPicker, useNavigation} from "react-day-picker";

export function DateComponent({label, helpText, ...props}) {
  const [date, setDate] = useState();
  const [field, meta, helpers] = useField(props.name);
  const [didFocus, setDidFocus] = useState(false);

  useEffect(() => {
    if (field.value && !didFocus && !date) {
      let newDate = new Date(field.value);
      newDate = newDate.setHours(newDate.getHours() + 6)
      setDate(new Date(newDate))
    }
  }, [field.value, date, didFocus]);

  const handleFocus = () => setDidFocus(true);
  const resetDate = async () => {
    setDate(undefined);
    await helpers.setValue('', true)
    helpers.setError('campo requerido')
    setDidFocus(true)
  };
  
  const  validateSelectDate = async (selectedDate) => {
    if(selectedDate){
      if (selectedDate > props.maxdate) {
        toastError('La fecha seleccionada supera la fecha permitida')
      } else if (selectedDate < props.mindate) {
        toastError('La fecha seleccionada es menor la fecha permitida')
      } else {
        setDate(selectedDate);
        await helpers.setValue(format(selectedDate, "yyyy-MM-dd", {locale: esLocale}), false);
      }
    }
  }

  return (
    <Popover placement="bottom" className="w-full">
      <div className="container-form-input">
      <PopoverHandler>
        <Input
          id='calendar'
          variant='static'
          className="cursor-pointer"
          size="md"
          type="text"
          readOnly
          {...field}
          {...props}
          label={label}
          onFocus={handleFocus}
          icon={
            <div className={`right-3 ml-[-20px] w-10 h-5 cursor-pointer flex flex-row-reverse justify-between ${props.disabled && '!cursor-not-allowed'}`}>
              {
                props.disabled? <CalendarIcon className="cursor-not-allowed"/> :
                <PopoverHandler><CalendarIcon/></PopoverHandler>
              }
              {
                field.value && !props.disabled?
                <XMarkIcon onClick={resetDate}/>: ''
              }
            </div>
          }
          error={meta.error && !!didFocus}
          onChange={() => {}}
          value={field.value? field.value : ''}
        />
      </PopoverHandler>
      {helpText && <Typography variant="small" className="text-[12px] font-normal">{helpText}</Typography>}
      <div className={`mt-1 flex justify-end items-end content-end px-2`}>
        <ErrorMessage name={field.name} component="div" className="text-red-500 text-[10px] font-normal"/>
      </div>
      </div>

      <PopoverContent>
        <DayPicker
          mode="single"
          selected={date}
          onSelect={validateSelectDate}
          showOutsideDays
          className="border-0"
          locale={esLocale}
          components={{
            Caption: CustomCaption
          }}
        />
      </PopoverContent>
    </Popover>
  );
}

function CustomCaption(props) {
  const {goToMonth, nextMonth, previousMonth} = useNavigation();

  return (
    <div className="w-full flex justify-between">
      {/*RETROCER UN ANIO*/}
      {/* <Tooltip content="Retroceder año" key='mes1' > */}
        <button
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth.setMonth(previousMonth.getMonth() - 11))}
        >
          <ChevronDoubleLeftIcon className="h-4 w-4 stroke-2"/>
        </button>
      {/* </Tooltip> */}
      {/*=============================================================================*/}
      {/*RETROCER UN MES*/}
      {/* <Tooltip content="Retroceder mes"> */}
        <button
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
        >
          <ChevronLeftIcon className="h-4 w-4 stroke-2"/>
        </button>
      {/* </Tooltip> */}

      {/*=============================================================================*/}
      {/*FECHA SELECCINADA*/}
      {format(props.displayMonth, 'MMMM yyy', {locale: esLocale})}
      {/*ADELANTAR UN MES*/}
      {/*=============================================================================*/}
      {/* <Tooltip content="Siguiente mes"> */}
        <button
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
        >
          <ChevronRightIcon className="h-4 w-4 stroke-2"/>
        </button>
      {/* </Tooltip> */}
      {/*=============================================================================*/}
      {/*ADELANTAR UN ANIO*/}
      {/* <Tooltip content="Siguiente año"> */}
        <button
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(nextMonth.setMonth(nextMonth.getMonth() + 11))}
        >
          <ChevronDoubleRightIcon className="h-4 w-4 stroke-2"/>
        </button>
      {/* </Tooltip> */}
    </div>
  );
}

