import {useEffect, useState} from "react";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";


function ProductListComponent(props) {
  const [didMount, setDidMount] = useState(false);
  const {list} = props;

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (didMount) {
      list();
    }
  }, [didMount, list]);

  const columnsName = [
    {
      selector: row => row.id,
      name: 'No.',
    },
    {
      selector: row => row.name,
      name: 'Nombre',
    },
    {
      selector: row => row.category?.description,
      name: 'Categoria',
    },
    {
      selector: row => row.status ? 'Activo' : 'Inactivo',
      name: 'Estado',
    }
  ]

  return (
    <>
      <ContainerComponent
        uri={'crear'}
        title="Productos"
        textBtnCreate={'Crear Producto'}
      >
        <TableWithStripedRows
          loader={props.loader}
          data={props.data}
          page={props.page}
          columns={columnsName}
          list={list}
          ChangeRowsPerPage={props.ChangeRowsPerPage}
          actionDelete={props.deleteRecord}
          searchChange={props.searchChange}
          searchValue={props.search}
        />
      </ContainerComponent>
    </>
  )

}

export default ProductListComponent