import { useEffect, useRef, useState } from "react";
import { useField} from 'formik'

export function ImagenFileComponent(props) {
  const [field, , helpers] = useField(props.name);
  const [didMount, setMount] = useState(false);
  const [imagen, setImagen] = useState();

  const fileRef = useRef();
  const imgRef = useRef();
  
  useEffect(()=>{
    setMount(true)    
  }, [])
  
  useEffect(()=>{
    if(didMount && typeof field.value === 'string'){
      setImagen(field.value)
    }

  }, [field.value, didMount])


  return (
    <div className="container-form-input-img min-h-[var(--height-img-input)]">
      <div>
        <label htmlFor={props.name} className="text-blue-gray-500 absolute" style={{ top: '-0.90rem' }}>
          Foto
        </label>
      </div>
      <div className="border h-full border-blue-gray-200 rounded-xl mt-2">
        <div className="container-img-input h-full rounded-xl p-[1.5px]">
          <div className="w-full h-full" >
            <img ref={imgRef} src={imagen ?? '/assets/loadImg.png'} alt="Foto" className={"rounded-xl img-fit "}/>
          </div>
          <div className="container-img-input2 flex h-full w-full rounded-xl my-auto">
            <label id="label-img" htmlFor={props.name} className="h-full w-full cursor-pointer flex flex-col items-center justify-center">
            </label>
          </div>
        </div>
      </div>

      <input
        id={props.name}
        ref={fileRef}
        className="hidden"
        size="md"
        type="file"
        name={props.name}
        onChange={(value) => {
          const [file] = fileRef.current?.files;
          if (file) {
            setImagen(URL.createObjectURL(file));
            helpers.setValue(file);
          }
        }}
      />
    </div>

  )
}
