const theme = {
  iconButton: {
    styles: {
      variants: {
        filled: {
          gray: {
            backgroud: "bg-gray-200",
            color: "text-black",
            shadow: "shadow-md shadow-gray-200/20",
            hover: "hover:shadow-lg hover:shadow-gray-200/90",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
        },
      },
    },
  },
  button: {
    defaultProps: {
      variant: "filled",
      size: "md",
      color: "light-green",
      fullWidth: false,
      ripple: true,
      className: "px-9 flex flex-row justify-center rounded-md",
    },
    styles: {
      base: {
        initial: {
          fontWeight: "font-normal",
          textAlign: "text-center",
          textTransform: "capitalize",
        },
      },
      sizes: {
        md: {
          fontSize: "text-sm",
          py: "py-2",
          px: "px-7",
        },
      },
      variants: {
        filled: {
          gray: {
            backgroud: "bg-gray-200",
            color: "text-black",
            shadow: "shadow-md shadow-gray-200/20",
            hover: "hover:shadow-lg hover:shadow-gray-200/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
        },
        outlined: {
          indigo: {
            border: "border border-indigo-500",
            color: "text-indigo-500",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-indigo-200",
            active: "active:opacity-[0.85]",
          },
        }
      },
    },
  },
  // list: {
  //   defaultProps: {
  //     ripple: true,
  //     className: "",
  //   },
  //   styles: {
  //     base: {
  //       list: {
  //         display: "flex",
  //         flexDirection: "flex-col",
  //         gap: "gap-0",
  //         minWidth: "min-w-[240px]",
  //         p: "p-2",
  //         fontFamily: "font-sans",
  //         fontSize: "text-base",
  //         fontWeight: "font-normal",
  //         color: "text-blue-gray-800",
  //       },
  //       item: {
  //         initial: {
  //           display: "flex",
  //           alignItems: "items-center",
  //           width: "w-full",
  //           padding: "py-3 pr-3 pl-4",
  //           borderRadius: "rounded-md",
  //           textAlign: "text-start",
  //           lightHeight: "leading-tight",
  //           transition: "transition-all",
  //           bg: "hover:bg-indigo-100 hover:bg-opacity-80 focus:bg-indigo-300 focus:bg-opacity-80 active:bg-indigo-300 active:bg-opacity-80",
  //           color: "hover:text-indigo-600 focus:text-indigo-600 active:text-text-indigo-600",
  //           outline: "outline-none",
  //         },
  //         selected: {
  //           bg: "bg-indigo-500",
  //           color: "text-white",
  //         },
  //         disabled: {
  //           opacity: "opacity-50",
  //           cursor: "cursor-not-allowed",
  //           pointerEvents: "pointer-events-none",
  //           userSelect: "select-none",
  //           bg: "hover:bg-transparent focus:bg-transparent active:bg-transparent",
  //           color: "hover:text-blue-gray-500 focus:text-blue-gray-500 active:text-blue-gray-500",
  //         },
  //       },
  //       itemPrefix: {
  //         display: "grid",
  //         placeItems: "place-items-center",
  //         marginRight: "mr-4",
  //       },
  //       itemSuffix: {
  //         display: "grid",
  //         placeItems: "place-items-center",
  //         marginRight: "ml-auto justify-self-end",
  //       },
  //     },
  //   },
  // },
  input: {
    defaultProps: {
      variant: "static",
      size: "md",
      color: "blue-gray-600",
      label: "",
      error: false,
      success: false,
      icon: undefined,
      labelProps: undefined,
      containerProps: undefined,
      shrink: false,
      className: "mt-3",
    },
    styles: {
      variants: {
        static: {
          base: {
            input: {
              borderWidth: "border",
              borderColor: "placeholder-shown:border-blue-gray-100",
              borderRadius: "rounded-1xl",
              padding: "",
              disabled: "disabled:cursor-not-allowed",
              color: "!text-black",
            },
            inputWithIcon: {
              pr: "!pr-7",
            },
            icon: {
              top: "top-2/4",
              right: "right-2",
              transform: "-translate-y-1/4",
            },
            label: {
              disabled: "peer-disabled:!text-blue-gray-700",
              position: "-top-3",
              fontSize: "text-sm peer-focus:text-sm",
              after: {
                content: "after:content[' ']",
                display: "after:block",
                width: "after:w-11/12",
                position: "after:absolute",
                bottom: "after:-bottom-4",
                left: "left-0",
                borderWidth: "after:border-none",
                scale: "after:scale-x-0",
                floated: {
                  scale: "peer-focus:after:scale-x-100",
                },
                transition: "after:transition-transform after:duration-300",
                color: "!text-black/90",
              },
            },
          },
          sizes: {
            md: {
              container: {
                height: "h-10",
                pb: "pb-1",
              },
              input: {
                fontSize: "text-sm",
                pt: "pt-1",
                pb: "pb-1",
                mt: 'mt-2',
                pl: 'pl-2.5',
              },
              label: {
                lineHeight: "peer-placeholder-shown:leading-tight",
              },
              icon: {
                width: "w-5",
                height: "h-5",
              },
            },
            lg: {
              container: {
                height: "h-12",
              },
              input: {
                fontSize: "text-sm",
                px: "px-px",
                pt: "pt-5",
                pb: "pb-2",
              },
              label: {
                lineHeight: "peer-placeholder-shown:leading-tight",
                disabled: "disabled:text-blue-gray-700",
              },
              icon: {
                width: "w-6",
                height: "h-6",
              },
            },
          },
          colors: {
            input: {
              black: {
                color: "!black",
                borderColor: "border-black",
                borderColorFocused: "focus:border-black",
              },
              white: {
                color: "text-white",
                borderColor: "border-white",
                borderColorFocused: "focus:border-white",
              },
              "blue-gray": {
                borderColor: "border-blue-gray-900",
                borderColorFocused: "focus:border-blue-gray-500",
              },
            },
            label: {
              black: {
                color: "!text-black peer-focus:black",
                after: "after:border-black peer-focus:after:border-black",
              },
              white: {
                color: "!text-white peer-focus:white",
                after: "after:border-white peer-focus:after:border-white",
              },
              "blue-gray": {
                color: "!text-blue-gray-800 peer-focus:text-blue-gray-800",
                after: "after:border-blue-gray-800 peer-focus:after:border-blue-gray-800",
              },
              gray: {
                color: "!text-blue-gray-800 peer-focus:text-gray-800",
                after: "after:border-gray-800 peer-focus:after:border-gray-800",
              },
            },
          },
          error: {
            input: {
              borderColor: "border-red-500 placeholder-shown:border-red-500",
              borderColorFocused: "focus:border-red-500",
            },
            label: {
              color: "text-red-500 peer-focus:text-red-500 peer-placeholder-shown:text-red-500",
              after: "after:border-red-500 peer-focus:after:border-red-500",
            },
          },
          success: {
            input: {
              borderColor: "border-green-500 placeholder-shown:border-green-500",
              borderColorFocused: "focus:border-green-500",
            },
            label: {
              color: "text-green-500 peer-focus:text-green-500 peer-placeholder-shown:text-green-500",
              after: "after:border-green-500 peer-focus:after:border-green-500",
            },
          },
          shrink: {
            input: {},
            label: {
              disabled: "disabled:!text-blue-gray-700",
            },
          },
        },
      },
    },
  },
  tabsHeader: {
    defaultProps: {
      className: "",
    },
    styles: {
      base: {
        display: "flex",
        position: "relative",
        bg: "bg-blue-gray-50",
        bgOpacity: "bg-opacity-60",
        borderRadius: "rounded-lg",
        p: "p-1",
      },

    },
  },
  typography: {
    defaultProps: {
      variant: "paragraph",
      color: "black",
      textGradient: false,
      className: "opacity-100",
    },
    valid: {
      variants: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "lead",
        "paragraph",
        "small",
      ],
      colors: [
        "inherit",
        "current",
        "black",
        "white",
        "blue-gray",
        "gray",
        "brown",
        "deep-orange",
        "orange",
        "amber",
        "yellow",
        "lime",
        "light-green",
        "green",
        "teal",
        "cyan",
        "light-blue",
        "blue",
        "indigo",
        "deep-purple",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      variants: {
        h1: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-5xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-tight",
        },
        h2: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-4xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-[1.3]",
        },
        h3: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-3xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-snug",
        },
        h4: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-2xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-snug",
        },
        h5: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-snug",
        },
        h6: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "font-sans",
          fontSize: "text-base",
          fontWeight: "font-semibold",
          lineHeight: "leading-relaxed",
        },
        lead: {
          display: "block",
          fontSmoothing: "antialiased",
          fontFamily: "font-sans",
          fontSize: "text-xl",
          fontWeight: "font-normal",
          lineHeight: "leading-relaxed",
        },
        paragraph: {
          display: "block",
          fontSmoothing: "antialiased",
          fontFamily: "font-sans",
          fontSize: "text-base",
          fontWeight: "font-light",
          lineHeight: "leading-relaxed",
        },
        small: {
          display: "block",
          fontSmoothing: "antialiased",
          fontFamily: "font-sans",
          fontSize: "text-sm",
          fontWeight: "font-light",
          lineHeight: "leading-normal",
        },
      },
      textGradient: {
        bgClip: "bg-clip-text",
        color: "text-transparent",
      },
      colors: {
        inherit: {
          color: "text-inherit",
        },
        current: {
          color: "text-current",
        },
        black: {
          color: "text-black",
        },
        white: {
          color: "text-white",
        },
        "blue-gray": {
          color: "text-blue-gray-900",
          gradient: "bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400",
        },
        gray: {
          color: "text-gray-700",
          gradient: "bg-gradient-to-tr from-gray-600 to-gray-400",
        },
        brown: {
          color: "text-brown-500",
          gradient: "bg-gradient-to-tr from-brown-600 to-brown-400",
        },
        "deep-orange": {
          color: "text-deep-orange-500",
          gradient: "bg-gradient-to-tr from-deep-orange-600 to-deep-orange-400",
        },
        orange: {
          color: "text-orange-500",
          gradient: "bg-gradient-to-tr from-orange-600 to-orange-400",
        },
        amber: {
          color: "text-amber-500",
          gradient: "bg-gradient-to-tr from-amber-600 to-amber-400",
        },
        yellow: {
          color: "text-yellow-500",
          gradient: "bg-gradient-to-tr from-yellow-600 to-yellow-400",
        },
        lime: {
          color: "text-lime-500",
          gradient: "bg-gradient-to-tr from-lime-600 to-lime-400",
        },
        "light-green": {
          color: "text-light-green-500",
          gradient: "bg-gradient-to-tr from-light-green-600 to-light-green-400",
        },
        green: {
          color: "text-green-500",
          gradient: "bg-gradient-to-tr from-green-600 to-green-400",
        },
        teal: {
          color: "text-teal-500",
          gradient: "bg-gradient-to-tr from-teal-600 to-teal-400",
        },
        cyan: {
          color: "text-cyan-500",
          gradient: "bg-gradient-to-tr from-cyan-600 to-cyan-400",
        },
        "light-blue": {
          color: "text-light-blue-500",
          gradient: "bg-gradient-to-tr from-light-blue-600 to-light-blue-400",
        },
        blue: {
          color: "text-blue-500",
          gradient: "bg-gradient-to-tr from-blue-600 to-blue-400",
        },
        indigo: {
          color: "text-indigo-500",
          gradient: "bg-gradient-to-tr from-indigo-600 to-indigo-400",
        },
        "deep-purple": {
          color: "text-deep-purple-500",
          gradient: "bg-gradient-to-tr from-deep-purple-600 to-deep-purple-400",
        },
        purple: {
          color: "text-purple-500",
          gradient: "bg-gradient-to-tr from-purple-600 to-purple-400",
        },
        pink: {
          color: "text-pink-500",
          gradient: "bg-gradient-to-tr from-pink-600 to-pink-400",
        },
        red: {
          color: "text-red-500",
          gradient: "bg-gradient-to-tr from-red-600 to-red-400",
        },
      },
    },
  },
};


export {theme}