import React from 'react';
import {useField, ErrorMessage} from 'formik';
import {Checkbox, Typography} from "@material-tailwind/react";

export function CheckboxComponent ({label, helpText, ...props}) {
  const [field, , helper] = useField(props.name);

  return (
  <div className="container-form-input">
    <Checkbox
      color='blue-gray'
      label={label}
      {...field}
      name={field.name}
      checked={field.value}
      onChange={async (e)=>{
        await helper.setValue(!field.value)
      }}
    />
    {helpText && <Typography variant="small" className="text-[12px] font-normal">{helpText}</Typography>}
    <div className="mt-1 flex justify-end items-end content-end px-2">
      <ErrorMessage name={field.name} component="div" className="text-red-500 text-[10px] font-normal "/>
    </div>
  </div>
  );
};


