import {useEffect, useState} from "react";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";

export function RoleList(props) {
  const [didMount, setDidMount] = useState(false);
  const {list} = props;

  useEffect(() => {
    setDidMount(true);
  }, []);
  
  useEffect(() => {
    if(didMount){
      list();
    }
  }, [didMount, list]);

  const columnsName = [
    {
      selector: row => row.id,
      name: 'Id',
    },
    {
      selector: row => row.name,
      name: 'Nomobre Rol',
    },
    {
      selector: row => row.last_update,
      name: 'Ultima actualización',
    },
  ]

  return (
    <ContainerComponent
      uri={'crear'}
      title="Roles"
      textBtnCreate={'Crear Rol'}
    >
      <TableWithStripedRows
        {...props}
        columns={columnsName}
        actionDelete={props.deleteRecord}
        searchChange={props.searchChange}
        searchValue={props.search}
      />
    </ContainerComponent>
  )

}