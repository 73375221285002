import {createReducer} from '../baseReducer'


export const {slice, extraActions} = createReducer(
  'category',
  'category',
  '/producto/categoria'
);


// Action creators are generated for each case reducer function
export const actions = {
  ...extraActions,
  ...slice.actions,
}

export default slice.reducer