import {useEffect, useState} from "react";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";
import {setDateString} from "../../../utils/shared";


export function CashRegisterList(props) {
  const [didMount, setDidMount] = useState(false);
  const {list} = props;

  useEffect(() => {
    setDidMount(true)
  }, []);

  useEffect(() => {
    if(didMount){
      list();
    }
  }, [didMount, list]);


  const columnsName = [
    {
      selector: row => row.id,
      name: 'Id',
    },
    {
      selector: row => row?.branch_office,
      name: 'Sucursal',
    },
    {
      selector: row => row?.user?.username,
      name: 'Usuario',
    },
    {
      selector: row => row.initial_amount,
      name: 'Saldo Inicial',
    },
    {
      selector: row => setDateString(row.date),
      name: 'Fecha Apertura',
    },
    {
      selector: row => setDateString(row.date),
      name: 'Fecha Cierre',
    },

  ]


  return (
    <ContainerComponent
      title="Caja Registradora"
    >
      <TableWithStripedRows
        classNameCard="px-0"
        {...props}
        dontShowAction={true}
        columns={columnsName}
        searchChange={props.searchChange}
        searchValue={props.search}
        actionDelete={props.deleteRecord}
      />
    </ContainerComponent>
  )

}