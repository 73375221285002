import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import React from "react";


export const ModalComponent = ({
                                 show, setShow,
                                 title = "Alerta!",
                                 message = "¿Estás seguro de que deseas continuar?",
                                 labelButtonCancel = "Cancelar",
                                 labelButtonConfirm = "Confirmar",
                                 onConfirm
                               }) => {
  const handleOpen = () => setShow(!show);

  const handleConfirm = () => {
    onConfirm()
    setShow(false)
  }


  return (
    <>
      <Dialog open={show} handler={handleOpen} size="xs" dismiss={{outsidePress: false}}>
        <DialogBody className="grid place-items-center gap-4">
          <Typography color="blue-gray" variant="h4">
            {/*¡Apertura de caja!*/}
            {title}
          </Typography>
          <Typography className="text-center font-normal text-black">
            {/*Estás a punto de eliminar un registro. Esta acción no se puede deshacer.*/}
            {/*¿Estás seguro de que deseas continuar?*/}
            {message}
          </Typography>

        </DialogBody>
        <DialogFooter className="">
          <div className="w-full flex md:flex-row lg:flex-row flex-col justify-between gap-3">
            <Button
              type="button"
              variant="filled"
              color="gray"
              onClick={handleOpen}
              className="">
              {/*<XCircleIcon className="w-6 h-6"/>*/}
              {labelButtonCancel}
            </Button>
            <Button variant="gradient" onClick={handleConfirm} color="red" className="">
              {/*<CheckIcon className="w-6 h-6"/>*/}
              {labelButtonConfirm}
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  )
}