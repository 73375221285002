import {connect} from 'react-redux';
import {UserList} from "./List/UserList";
import {CreateUser} from "./Create/CreateUser";
import {actions} from '../../js/redux/user';

const usersState = (state) => {
  return {
    ...state.users,
  };
};


const CreateUserConn = connect(usersState, actions)(CreateUser);
const UserListConn = connect(usersState, actions)(UserList);

export {
  CreateUserConn,
  UserListConn,
}