import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {required} from "../../../components/elements/Forms/validations";
import {FormComponent} from "../../../components/elements/Forms/FormComponent";
import ContainerComponent from "../../../components/elements/ContainerComponent";


export function CreateBranchOffices(props) {
  const {isView = false, isUpdate = false, item, retrieve} = props;
  const [didMount, setMount] = useState(false)
  const nav = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    setMount(true)
  }, []);
  
  useEffect(() => {
    if (id && didMount){
      retrieve(id);
    }
  }, [id, didMount, retrieve]);

  const fields = {
    name: {label: 'Nombre', validation: required()},
    // manager: {label: 'Encargado', validation: required(), type: 'select', urlOptionList: 'user/select_option'},
    department: {label: 'Departamento', validation: required(), type: 'select', urlOptionList: 'department/select_option'},
    municipality: {label: 'Municipio', validation: required(), type: 'select', urlOptionList: 'municipality/select_option', dependence: 'department'},
    address: {label: 'Dirección', validation: required()},
    phone_number: {label: 'Teléfono', validation: required().min(8, 'El campo debe tener mínimo 8 dígitos'), type: 'number', maxLength: '8'},
    email: {label: 'Correo Electrónico', validation: required().email('Debe ingresar un correo valido')},
  }

  const handleSubmit = (data) => {
    if(id){
      props.update(id, data, nav)
    } else {
      props.create(data, nav)
    }
  }
  return (
    <>
      <ContainerComponent
        title={isUpdate ? "Editar Sucursal" : isView? `Sucursal ${id}` : "Crear Sucursal"}
      >
        <FormComponent
          isUpdate={isUpdate}
          isView={isView}
          fields={fields}
          data={item}
          actionSubmit={handleSubmit}
          pathBack={'/sucursal'}
        />
      </ContainerComponent>
    </>
  )
}