import {connect} from 'react-redux';
import {ProveedoresList} from "./List/ProveedoresList";
import {Proveedores} from "./Create/Proveedores";
import {actions} from '../../js/redux/Proveedores/Proveedores';

const ProveedoresState = (state) => {
  return {
    ...state.proveedores,
  };
};


const CrearProveedor = connect(ProveedoresState, actions)(Proveedores);
const ListarProveedores = connect(ProveedoresState, actions)(ProveedoresList);

export {
  CrearProveedor,
  ListarProveedores,
}