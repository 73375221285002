import axios from 'axios';
import {toastError, toastInfo} from '../toast';
import {redirect} from "react-router-dom";


const pathApi = process.env.REACT_APP_API_URL;


let instance = axios.create({
  baseURL: pathApi,
  timeout: 40000,
  validateStatus: function (status) {
    if([500].includes(status)){
      toastInfo('El servidor tardo en responder, prueba mas tarde')
      return false;
    }
    else if([401, 403].includes(status)){
      toastError('No tienes acceso para realizar esta acción')
      redirect("/login")
      return false
    }
    return status >= 200 && status < 301; // default
  },
});

function getToken() {
  let Token = localStorage.getItem('token');
  if(Token){
    Token = `Token ${Token}`
  } else {
    Token = ''
  }
  return Token;
}

function getInstanceWithCredential(type) {
  let service = instance
  service.defaults.headers.common.Authorization = getToken();
  service.defaults.headers.common["Content-Type"] = type ?? "application/json";
  return service
}
function setToken(token) {
  localStorage.setItem('token', token);
}

function removeToken() {
  localStorage.removeItem('token');
  localStorage.removeItem('views');
  localStorage.removeItem('user');
}


async function get(url, params) {
  return await getInstanceWithCredential().get(fixUrl(url), {params: params});
}

async function post(url, data) {
  return await getInstanceWithCredential().post(fixUrl(url), data);
}

async function remove(url) {
  return await getInstanceWithCredential().delete(fixUrl(url));
  // .then(function (response) {
  //   // handle success
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   // handle error
  //   console.log(error);
  // })
  // .finally(function () {
  //   // always executed
  // });
}

async function put(url, data) {
  return await getInstanceWithCredential().put(fixUrl(url), data)
}

async function putFormData(url, formData) {
  let newFormData = new FormData();
  for (const formDataKey in formData) {
    newFormData.append(formDataKey, formData[formDataKey]);
  }

  return await getInstanceWithCredential('multipart/form-data').put(fixUrl(url), newFormData)
}

async function postFormData(url, formData) {
  let newFormData = new FormData();
  for (const formDataKey in formData) {
    newFormData.append(formDataKey, formData[formDataKey]);
  }

  return await getInstanceWithCredential('multipart/form-data').post(fixUrl(url), newFormData)
}

function fixUrl(url) {
  let newUrl = url;
  if(newUrl.endsWith('/')){
    return newUrl
  } else {
    return newUrl + '/'
  }
}

const api = {get, put, post, remove, getToken, setToken, removeToken, putFormData, postFormData};
export default api;