import {connect} from 'react-redux';
import ProductListComponent from "./List/ProductList";
import {CreateProductComponent} from "./Create/CreateProductComponent";
import {actions} from '../../js/redux/products/products';
import {CreateCategoriesConn, CategoriesListConn} from "./Categories"

const productState = (state) => {
  return {
    ...state.product,
  };
};


const CreateProductConn = connect(productState, actions)(CreateProductComponent);
const ProductsListConn = connect(productState, actions)(ProductListComponent);

export {
  CreateProductConn,
  ProductsListConn,
  CreateCategoriesConn,
  CategoriesListConn,
}