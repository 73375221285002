import {useNavigate, useParams} from "react-router-dom";
import {required} from "../../../components/elements/Forms/validations";
import {FormComponent} from "../../../components/elements/Forms/FormComponent";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {setDateInput} from "../../../utils/shared";


export function CreateCashRegister(props) {
  const {user} = props;
  const nav = useNavigate();
  const {id} = useParams();

  const fields = {
    opening_date: {label: 'Fecha', type: 'date', value: '14/06/2024', disabled: true},
    user: {label: 'Usuario / Cajero', type: 'select', maxLength: '12', disabled: true, options: [{value: user?.id, label: user?.username}]},
    branch_office: {
      label: 'Sucursal',
      urlOptionList: 'branch-offices/select',
      type: 'select', validation: required(),
      disabled: !(user?.views === 'is_superuser')
    },
    initial_amount: {
      label: 'Saldo Inicial',
      validation: required().min(1, 'El campo debe tener mínimo 1 dígitos'),
      type: 'number',
      maxLength: '10'
    },
  }

  const initialState = {
    opening_date: setDateInput(new Date()),
    user: user?.id,
    branch_office: user?.branch_office,
    initial_amount: 0,
    status: 1,
  }

  const handleSubmit = (data) => {
    if (id) {
      props.update(id, data, nav)
    } else {
      props.create(data, nav)
    }
  }

  return (
    <>
      <ContainerComponent
        title={"Apertura de caja"}>
        <FormComponent
          isUpdate={false}
          isView={false}
          fields={fields}
          data={initialState}
          actionSubmit={handleSubmit}
          pathBack={'/cajas'}
        />
      </ContainerComponent>
    </>
  )
}