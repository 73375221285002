import {connect} from 'react-redux';
import {actions} from '../../js/redux/branchOffices/branchOffices';
import {ListBranchOffices} from './List/ListBranchOffices';
import {CreateBranchOffices} from './Create/CreateBranchOffices';

const branchOfficesState = (state) => {
  return {
    ...state.branchOffice,
  };
};


const BranchOfficeListConn = connect(branchOfficesState, actions)(ListBranchOffices);
const CreateBranchOfficeConn = connect(branchOfficesState, actions)(CreateBranchOffices);


export {
  BranchOfficeListConn,
  CreateBranchOfficeConn,
}