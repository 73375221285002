import {createBrowserRouter, Navigate, Outlet} from "react-router-dom";
import LayoutComponent from "../../components/layout/layout";
import LoginComponent from "../../modules/public-pages/Login";
import ResetPassword from "../../modules/public-pages/ResetPassword";
import {DashboardComponentConn} from "../../modules/Dashboard/Dashboard";
import PageNotFoundComponente from "../../components/layout/PageNotFound/PageNotFound";
import {CreateCustomerConn, CustomerListConn} from "../../modules/Customer";
import {CrearProveedor, ListarProveedores} from "../../modules/Proveedores";
import {CreateUserConn, UserListConn} from "../../modules/User";
import {CreateRoleConn, RoleListConn} from "../../modules/Role";
import {CreateSalesConn, ListSalesConn} from "../../modules/Sales";
import {InventoryListConn} from "../../modules/Inventory";
import {BranchOfficeListConn, CreateBranchOfficeConn} from "../../modules/BranchOffices";
import {CashRegisterClosingConn, CashRegisterListConn, CreateCashRegisterConn} from "../../modules/AdminCashRegister";
import {CategoriesListConn, CreateCategoriesConn, CreateProductConn, ProductsListConn} from "../../modules/Products";
import {ProfileConn} from "../../modules/Profile";
import {PurchaseListConn, CreatePurchaseConn} from "../../modules/Purchase";
import {WarehouseListConn, CreateWarehouseConn} from "../../modules/Warehouse";
import {CrearCredito, ListarCreditos} from "../../modules/VentaCredito/index";
import {FormResetPasswordConn} from "../../modules/ResetPassword";


const ProtectedRoute = () => {
  let token = localStorage.getItem('token')
  if (!token) {
    return <Navigate to="/login" replace/>;
  }

  return <LayoutComponent/>;
};

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <ProtectedRoute/>,
    children: [
      {
        path: "",
        index: true,
        element: <DashboardComponentConn/>,
      }, {
        path: "perfil",
        element: <ProfileConn/>,
      },
      {
        path: "sucursal",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <BranchOfficeListConn/>},
          {path: 'crear', element: <CreateBranchOfficeConn/>},
          {path: 'editar/:id', element: <CreateBranchOfficeConn isUpdate/>},
          {path: 'detalle/:id', element: <CreateBranchOfficeConn isView/>},
        ]
      },
      {
        path: "inventario",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <InventoryListConn/>},
        ]
      },
      {
        path: "cliente",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <CustomerListConn/>},
          {path: 'crear', element: <CreateCustomerConn/>},
          {path: 'editar/:id', element: <CreateCustomerConn isUpdate/>},
          {path: 'detalle/:id', element: <CreateCustomerConn isView/>},
        ]
      },
      {
        path: "venta",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <ListSalesConn/>},
          {path: "crear", element: <CreateSalesConn/>},
        ]
      },
      {
        path: "usuario",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <UserListConn/>},
          {path: 'crear', element: <CreateUserConn/>},
          {path: 'editar/:id', element: <CreateUserConn isUpdate/>},
          {path: 'detalle/:id', element: <CreateUserConn isView/>},
        ]
      },
      {
        path: "producto",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <ProductsListConn/>},
          {path: 'crear', element: <CreateProductConn/>},
          {path: 'editar/:id', element: <CreateProductConn isUpdate/>},
          {path: 'detalle/:id', element: <CreateProductConn isView/>},
          {path: 'categoria', element: <CategoriesListConn isView/>},
          {path: 'categoria/crear', element: <CreateCategoriesConn/>},
          {path: 'categoria/editar/:id', element: <CreateCategoriesConn isUpdate/>},
          {path: 'categoria/detalle/:id', element: <CreateCategoriesConn isView/>}
        ]
      },
      {
        path: "roles",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <RoleListConn/>},
          {path: 'crear', element: <CreateRoleConn/>},
          {path: 'editar/:id', element: <CreateRoleConn isUpdate/>},
          {path: 'detalle/:id', element: <CreateRoleConn isView/>},
        ]
      },
      {
        path: "cajas",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <CashRegisterListConn/>},
          {path: 'apertura', element: <CreateCashRegisterConn/>},
          {path: 'cierre', element: <CashRegisterClosingConn/>},
        ]
      },
      {
        path: "reporte",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <BranchOfficeListConn/>},
          {path: 'crear', element: <CreateBranchOfficeConn/>},
          {path: 'editar/:id', element: <CreateBranchOfficeConn isUpdate/>},
          {path: 'detalle/:id', element: <CreateBranchOfficeConn isView/>},
        ]
      },
      {
        path: "proveedor",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <ListarProveedores/>},
          {path: 'crear', element: <CrearProveedor/>},
          {path: 'editar/:id', element: <CrearProveedor isUpdate/>},
          {path: 'detalle/:id', element: <CrearProveedor isView/>},
        ]
      },
      {
        path: "venta-credito",
        element: <div><Outlet/></div>,
        children: [
          {path: "", element: <ListarCreditos/>},
          {path: 'crear', element: <CrearCredito/>},
          {path: 'editar/:id', element: <CrearCredito isUpdate/>},
          {path: 'detalle/:id', element: <CrearCredito isView/>},
        ]
      },
      {
        path: "compra",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <PurchaseListConn/>},
          {path: 'crear', element: <CreatePurchaseConn/>},
          {path: 'editar/:id', element: <CreatePurchaseConn isUpdate/>},
          {path: 'detalle/:id', element: <CreatePurchaseConn isView/>},
        ]
      },
      {
        path: "bodega",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <WarehouseListConn/>},
          {path: 'crear', element: <CreateWarehouseConn/>},
          {path: 'detalle/:id', element: <CreateWarehouseConn isView/>},
          {path: 'editar/:id', element: <CreateWarehouseConn isUpdate/>},
        ]
      },
      {
        path: "cambiar-credenciales",
        element: <div><Outlet/></div>,
        children: [
          {path: "", index: true, element: <FormResetPasswordConn/>},
        ]

      }

    ],
    errorElement: <PageNotFoundComponente/>,
  },
  // PUBLIC PAGES
  {
    path: "login",
    index: true,
    element: <LoginComponent/>,
    errorElement: <PageNotFoundComponente/>,
  },
  {
    path: "resetear-contrasena",
    index: true,
    element: <ResetPassword/>,
    errorElement: <PageNotFoundComponente/>,
  },
  {
    path: "*",
    element: <PageNotFoundComponente/>,
  }
], {
  // future: {
  //   // v7_normalizeFormMethod: true,
  // },

});


export default router;
