import React from 'react';
import {
  ListItemPrefix,
  Avatar,
  Navbar,
  Popover,
  PopoverHandler,
  PopoverContent,
  Typography,
  List,
  ListItem
} from "@material-tailwind/react";
import {PowerIcon, UserCircleIcon, KeyIcon, Bars3Icon} from "@heroicons/react/24/solid";
import {NavLink, useNavigate} from "react-router-dom";


export function NavbarComponent(props) {
  let user = localStorage.getItem('user')
  if(user){
    user = JSON.parse(user)
  }
  const nav = useNavigate()

  const showSideBar = () => {
    const sidebar = document.getElementById('sidebarContainer');
    sidebar.classList.remove('hidden')
    sidebar.classList.add('absolute')
    sidebar.classList.add('z-20')
    document.body.classList.add('overscroll-none')
    document.body.classList.add('overflow-hidden')
  }

  const logOutAction = async () => {
    props.logOut(nav)
  }


  return (
    <div
      id="navbar"
      className="lg:w-[calc(100%-21rem)] lg:ml-[19rem] w-full fixed z-10 lg:relative xl:relative md:relative py-0 xl:py-3 lg:py-3 md:py-3 ">
      <Navbar
        className="px-4 border border-blue-gray-50 z-20 py-2 content-center items-center min-w-full flex justify-between rounded-none">
        <Bars3Icon className="w-6 h-6 md:hidden lg:hidden xl:hidden" color="black" onClick={showSideBar}/>
        {
          user?.branch_office_name ?
            <div className="bg-blue-gray-50 py-1 px-2 ">
              <Typography className="text-black" variant="small">
                Sucursal: {user.branch_office_name}
              </Typography>
            </div> :
            <div></div>
        }
        <div className="flex md:order-2">
          <Popover placement="bottom-end">
            <PopoverHandler>
              <div className="w-full flex flex-row cursor-pointer">
                <Typography variant="paragraph" color="gray" className="grid items-center">
                  {user?.username ?? " "}
                </Typography>
                <Avatar
                  alt="Usuario"
                  src={'/assets/logoAgro.jpg'}
                  className="w-9 h-9 ml-2"
                />
              </div>
            </PopoverHandler>
            <PopoverContent className="w-72 z-10">
              <div className="mb-2 flex items-center gap-4 border-b border-blue-gray-50 pb-4">
                <Typography
                  color="gray"
                  variant="small"
                  className="font-medium text-blue-gray-500"
                >
                  {user?.first_name ? user?.first_name + ' ' + user?.last_name : ''}
                </Typography>
              </div>
              <List className="p-0">
                <NavLink to="/perfil" className="text-initial font-medium text-blue-gray-500">
                  <ListItem>
                    <ListItemPrefix>
                      <UserCircleIcon className="w-5 h-5"/>
                    </ListItemPrefix>
                    Mi perfil
                  </ListItem>
                </NavLink>
                <NavLink to="/cambiar-credenciales" className="text-initial font-medium text-blue-gray-500">
                  <ListItem>
                    <ListItemPrefix>
                      <KeyIcon className="w-5 h-5"/>
                    </ListItemPrefix>
                    Cambiar Contraseña
                  </ListItem>
                </NavLink>
                <div className="text-initial font-medium text-blue-gray-500" onClick={logOutAction}>
                  <ListItem>
                    <ListItemPrefix>
                      <PowerIcon className="w-5 h-5"/>
                    </ListItemPrefix>
                    Cerrar Sesión
                  </ListItem>
                </div>
              </List>
            </PopoverContent>
          </Popover>
        </div>
      </Navbar>
    </div>

  );
}

export const toggleShowSidebar = ()=>{
  const sidebar = document.getElementById('sidebarContainer');
  const outlet = document.getElementById('outlet');
  const navbar = document.getElementById('navbar');
  sidebar.classList.toggle('!hidden')
  outlet.classList.toggle('lg:ml-[19rem]')
  outlet.classList.toggle('lg:w-[calc(100%-21rem)]')
  outlet.classList.toggle('max-h-[calc(100vh-3rem)]')
  navbar.classList.toggle('lg:w-[calc(100%-21rem)]')
  navbar.classList.toggle('px-6')
  navbar.classList.toggle('lg:ml-[19rem]')
  outlet.classList.toggle('w-auto')
  outlet.classList.toggle('px-6')
}