import {useState, useEffect, useRef} from 'react'
import {
  Button,
  Dialog,
  DialogBody, DialogFooter, IconButton,
  Typography,
} from "@material-tailwind/react";
import {PrinterIcon} from "@heroicons/react/24/solid";
import { BillFormat } from './BillFormat'
import { setMoney } from '../../../utils/shared';
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";
import {useReactToPrint} from "react-to-print";


export function BillModal(props) {
  const [didMount, setMount] = useState(false);
  const { row, retrieve, closeModal, show, item, loader } = props;
  const componentRef = useRef(null);


  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (didMount && row?.id) retrieve(row.id)
  }, [didMount, row, retrieve])

  const handleOpen = () => closeModal(show === true ? 0 : 1);

  const handlePrint = useReactToPrint({
    documentTitle: "Comprobante de compra",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => {
      console.log("after printing...")
      const container = document.getElementById('subcontainerPurchaseReceipt')
      container.classList.add('shadow-md')
      container.classList.add('border')
      container.classList.add('border-black/20')
    },
    removeAfterPrint: true,
    content: () => {
      const container = document.getElementById('subcontainerPurchaseReceipt')
      container.classList.remove('shadow-md')
      container.classList.remove('border')
      container.classList.remove('border-black/20')
      return componentRef.current
    },
  });


  return (
    <>
      <Dialog
        open={show}
        handler={handleOpen}
        size="sm"
        dismiss={{ outsidePress: false }}
      >
        <DialogBody className="grid place-items-center gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="red"
            className="mr-3 h-6 w-6 right-2 top-5 absolute cursor-pointer"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
          <Typography color="blue-gray" variant="h4" className='mt-3'>
            Detalle de compra
          </Typography>
          <div className='w-full'>
            <LoaderEllipsisComponent loader={loader}/>
            <BillFormat
              {...item}
              componentRef={componentRef}
              totalSales={setMoney(row.total ?? 0)}
            />
          </div>

        </DialogBody>
        <DialogFooter className="pt-0 pb-4">
          <div className="w-full">
            <IconButton variant="text" onClick={handlePrint} className="right-0">
              <PrinterIcon className="w-6 h-6"></PrinterIcon>
            </IconButton>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  )
}
