import {useState, useRef, useEffect} from 'react';
import Container from "../../../components/elements/ContainerComponent"
import {SearchProduct} from './SearchProduct';
import {ProductSelectedComponent} from './ProductSelected';
import {NavLink, useNavigate} from "react-router-dom";
import {setMoney} from '../../../utils/shared'
import {toastError, toastInfo} from "../../../js/toast";
import {
  EllipsisVerticalIcon,
  QueueListIcon,
  ArrowRightOnRectangleIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/solid";
import {IconButton, Menu, MenuHandler, MenuItem, MenuList} from "@material-tailwind/react";
import {PrimaryButton, SecondaryButton} from "../../../components/elements/Inputs/ButtonsComponents";
import {toggleShowSidebar} from "../../../components/layout/Navbar";
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";


export function CreateSales(props) {
  const [didMount, setMount] = useState(false);
  const [itemSelected, setItemSelected] = useState([]);
  const refNitInput = useRef();
  const nav = useNavigate();
  const {user} = props;

  useEffect(() => {
    hasCashRegister()
    setMount(true)
  }, [])

  useEffect(() => {
    if (didMount && user) {
      hasCashRegister()
    }
  }, [didMount, user])

  const hasCashRegister = () => {
    let localUser = localStorage.getItem('user')
    if (localUser) {
      localUser = JSON.parse(localUser)
      if (!localUser?.cash_register) {
        toastError('No tiene una caja abierta')
        nav('/cajas/apertura')
      }
    }
  }

  const addItemSelectedValidation = (element) => {
    let exist = false;
    let newArray = itemSelected.map((ele) => {
      if (ele.id === element.id && ele.price_type === element.price_type && ele.warehouse?.id === element.warehouse?.id) {
        if (ele.quantity > ele.warehouse.quantity) {
            toastInfo(`Solo existian ${ele.quantity} ${ele.name} disponibles`)
        }
        ele.quantity = parseInt(ele.quantity) + parseInt(element.quantity);
        exist = true;
      }
      return ele
    })
    if (!exist) {
      newArray.push(element)
    }
    setItemSelected(newArray)
  }

  const changeValueItemSelected = (element, quantity) => {
    let newArray = itemSelected.map((ele) => {
      if (ele.id === element.id) ele.quantity = quantity ? quantity : 0;
      return ele
    })
    setItemSelected(newArray)
  }

  const getTotal = (withTag = false) => {
    let total = 0;
    itemSelected.map((item) => {
      total += (item.price * item.quantity)
    })
    if (withTag)
      return total
    else
      return setMoney(total)
  }

  const removeItem = (id) => {
    let newArray = itemSelected.filter((ele) => ele.id !== id)
    setItemSelected(newArray)
  }

  const createSales = () => {
    console.log('user', user)
    if (!user?.cash_register) {
      toastError('No tienes una caja aperturada, apertura una o recarga la pagina')
      return;
    }
    const data = {
      nit: refNitInput.current?.value.length > 0 ? refNitInput.current?.value : "CF",
      sale_details: itemSelected,
      total: getTotal(true),
      branch_office: user.cash_register.branch_office,
      cash_register: user.cash_register.id,
    }
    props.create(data, nav)
  }


  return (
    <Container
      title="Registrar una venta"
      customButton={
        <Menu placement="left-start">
          <MenuHandler>
            <IconButton className="rounded-none"><EllipsisVerticalIcon className="w-5 h-5 text-black"/></IconButton>
          </MenuHandler>
          <MenuList>
            <MenuItem className="w-full">
              <NavLink to="/venta" className="flex flex-row items-end">
                <QueueListIcon className="w-5 h-5 mr-2"/>
                Ventas realizadas
              </NavLink>
            </MenuItem>
            <MenuItem className="w-full">
              <NavLink to="/cajas/cierre" className="flex flex-row items-end">
                <ArrowRightOnRectangleIcon className="w-5 h-5 mr-2"/>
                Cerrar caja
              </NavLink>
            </MenuItem>
            <MenuItem className="flex flex-row items-end" onClick={toggleShowSidebar}>
              <ArrowsPointingOutIcon className="w-5 h-5 mr-2"/>
              Expadir / Contraer
            </MenuItem>
          </MenuList>
        </Menu>
      }>
      <LoaderEllipsisComponent loader={props.loader}/>
      <div className='grid gap-2 grid-cols-12'>
        <div className='col-span-12 lg:col-span-5 xl:col-span-5  2xl:col-span-5  min-h-full'>
          <SearchProduct
            {...props}
            addItem={addItemSelectedValidation}
          />
        </div>
        <div className='col-span-12 lg:col-span-7 xl:col-span-7 2xl:col-span-7  h-full'>
          <ProductSelectedComponent
            {...props}
            totalSales={getTotal()}
            items={itemSelected}
            removeItem={removeItem}
            refNitInput={refNitInput}
            changeValueItemSelected={changeValueItemSelected}/>
        </div>
      </div>
      <div className="flex md:flex-row lg:flex-row flex-col justify-between gap-3 mt-6">
        <SecondaryButton
          type="button"
          text={'Regresar'}
          onClick={() => nav('/venta')}
        />
        <PrimaryButton
          onClick={createSales}
          text={'Finalizar venta'}
          disabled={!itemSelected.length}
        />
      </div>
    </Container>
  )
}
