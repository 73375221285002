import ContainerComponent from "../../../../components/elements/ContainerComponent";
import { TableWithStripedRows } from "../../../../components/elements/Tables/TableComponent";
import { useEffect, useState } from "react";
import { TagIcon } from "@heroicons/react/24/solid";


function CategoriesListComponent(props) {
  const [didMount, setDidMount] = useState(false);
  const { data, list } = props;

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (didMount) {
      list();
    }
  }, [didMount, list]);

  const columnsName = [
    {
      selector: row => row.id,
      name: 'No.',
      sortable: true,
    },
    {
      selector: row => row.description,
      name: 'Descripción',
      sortable: true,

    },
    {
      selector: row => row.color,
      variant: 'color',
      name: 'Color',
      cell: row => <TagIcon className="w-5 h-5" color={row.color} />,
    },
  ]

  return (
    <ContainerComponent
      uri={'crear'}
      title="Categoría de Productos"
      textBtnCreate={'Crear categoría'}
    >
      <TableWithStripedRows
        {...props}
        columns={columnsName}
        searchChange={props.searchChange}
        searchValue={props.search}
        actionDelete={props.deleteRecord}
      />
    </ContainerComponent>
  )

}

export { CategoriesListComponent }