import React from "react";
import {FieldArray, Form} from "formik";
import FormPresentation from "./FormPresentation";
import {InputComponent, SelectComponent} from "../../../components/elements/Inputs";
import {PrimaryButton, SecondaryButton} from "../../../components/elements/Inputs/ButtonsComponents";
import {Typography} from "@material-tailwind/react";

function FormMainProduct({goBack, isUpdate, isView, changeAction, ...other}) {
  return (
    <Form className="h-[calc(100vh-16rem)]">
      {/*//Un div que sea de 2 columnas en pantallas grandes y 1 columna en pantallas pequeñas*/}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="space-y-4 shadow-gray-400 shadow px-4 py-6 h-fit">
            <Typography variant="h3" className="text-xl font-semibold">Datos Producto</Typography>
            <InputComponent name='name' label='Nombre de producto' disabled={isView}/>
            <SelectComponent
              name='category'
              label='Categoría'
              disabled={isView}
              urlOptionList='/category/select_option'
            />
            <InputComponent name='description' label='Descripción' type='textarea' disabled={isView}/>
          </div>
        <div className="space-y-4 shadow-gray-400 shadow px-4 py-6">
          <FieldArray
            name="presentations"
            render={(fieldProps) => (
              <FormPresentation
                {...fieldProps}
                isView={isView}
                isUpdate={isUpdate}/>
            )}
          />
        </div>
      </div>
      <div className="absolute w-11/12 flex md:flex-row lg:flex-row flex-col justify-between gap-3 bottom-5">
        <SecondaryButton
          type="button"
          text={'Regresar'}
          onClick={goBack}
        >
        </SecondaryButton>
        {
          !isView &&
          <PrimaryButton
            type="submit"
            disabled={!other.isValid}
            text={isUpdate ? 'Actualizar' : 'Crear'}>
          </PrimaryButton>
        }
      </div>
    </Form>

  )
}


export default FormMainProduct