import { useState, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogBody,
  DialogFooter, IconButton,
  Typography,
} from "@material-tailwind/react";
import { BillFormat } from './BillFormat'
import { setMoney } from '../../../utils/shared';
import { useReactToPrint } from "react-to-print";
import {PrinterIcon} from "@heroicons/react/24/solid";


export function BillModal(props) {
  const [didMount, setMount] = useState(false);
  const { row, retrieve, closeModal, show, item } = props;
  const { sale_details } = item;
  const componentRef = useRef(null);

  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (didMount && row?.id) retrieve(row.id)
  }, [didMount, row])

  const handleOpen = () => closeModal(show === true ? 0 : 1);

  const confirmAction = () => {
    // confirmCallBack()
    // printDiv()
    handlePrint()
    // handleOpen()
  }

  const handlePrint = useReactToPrint({
    documentTitle: "Recibo",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
    content: () => componentRef.current,
  });

  return (
    <>
      <Dialog
        open={show}
        handler={handleOpen}
        size="sm"
        dismiss={{ outsidePress: false }}
      >
        <DialogBody className="grid place-items-center gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="red"
            className="mr-3 h-6 w-6 right-2 top-5 absolute cursor-pointer"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
          <Typography color="blue-gray" variant="h4" className='mt-3'>
            Recibo de venta
          </Typography>
          <div className='w-full'>
            <BillFormat
              items={sale_details ?? []}
              componentRef={componentRef}
              totalSales={setMoney(row.total ?? 0)}
            />
          </div>

        </DialogBody>
        <DialogFooter className="pt-0 pb-4">
          <div className="w-full justify-end">
            <IconButton variant="text" onClick={handlePrint} className="right-0">
              <PrinterIcon className="w-6 h-6"></PrinterIcon>
            </IconButton>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  )
}
