import {connect} from 'react-redux';
import {WarehouseList} from "./List/WarehouseList";
import {CreateWarehouse} from "./Create/CreateWarehouse";
import {actions} from '../../js/redux/warehouse/warehouse';

const customerState = (state) => {
  return {
    ...state.warehouse,
  };
};


const CreateWarehouseConn = connect(customerState, actions)(CreateWarehouse);
const WarehouseListConn = connect(customerState, actions)(WarehouseList);

export {
  CreateWarehouseConn,
  WarehouseListConn,
}