import {connect} from 'react-redux';
import {actions} from '../../../js/redux/auth';
import Login from './Login';

const userState = (state) => {
  return {
    ...state.user,
  };
};


export default connect(userState, actions)(Login);