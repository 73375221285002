import {createReducer} from '../baseReducer'


const {slice, extraActions} = createReducer(
  'branchOffice',
  'branch-offices',
  '/sucursal'
);

// Action creators are generated for each case reducer function
export const actions = {
  ...extraActions,
  ...slice.actions,
}

export default slice.reducer