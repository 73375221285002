import {connect} from 'react-redux';
import {actions} from '../../js/redux/auth';
import {FormResetPassword} from './FormResetPassword';

const userState = (state) => {
  return {
    ...state.user,
  };
};


const FormResetPasswordConn =  connect(userState, actions)(FormResetPassword);
export {
  FormResetPasswordConn
}