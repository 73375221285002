import React from "react";
import {useField} from "formik";

export function InputColor({label, helpText, ...props}) {
  const [field] = useField(props);
  return (
    <div className="container-form-input">
      <div className="flex items-center">
        <div className="w-10 h-10 rounded-full bg-gray-200 border border-gray-300 mr-2 cursor-pointer"
             style={{backgroundColor: field.value}}
             onClick={() => {
               const input = document.querySelector(`input[name="${field.name}"]`);
               input.click();
             }}>
        </div>
        <input
          {...props}
          {...field}
          type="color"
          className="w-2 h-2 rounded-full border-none border-o mr-2 outline-none focus:ring-0 focus:outline-none opacity-0"
        />
      </div>
    </div>
  );
}