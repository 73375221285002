import {useEffect, useState} from "react";
import {setDatetime, setMoney} from "../../../utils/shared";
import {IconButton} from "@material-tailwind/react";
import {NoSymbolIcon} from "@heroicons/react/24/solid";
import {ClipboardDocumentCheckIcon} from "@heroicons/react/24/outline";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";
import {DialogOfDeleteComponent} from "../../../components/elements/Dialogs/DialogComponent";
import {BillModal} from "./BillModal";
import {useLocation} from 'react-router-dom';
import {connect} from "react-redux";
import {actions} from "../../../js/redux/compra/compra";
import {CreatePurchase} from "../Create/CreatePurchase";

export function PurchaseList(props) {
  const [didMount, setDidMount] = useState(false);
  const [showModalCancellation, setShowModal] = useState(false);

  const [idModal, setIdModal] = useState(0);
  const [currentRow, setCurrentRow] = useState({});
  const [rowSelect, setRowSelect] = useState();
  const {list, setItem} = props;

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (didMount) {
      list();
    }
  }, [didMount, list]);

  const showCancellation = (row) => {
    setShowModal(true)
    setRowSelect(row)
  }

  const confirmCancellation = () => {
    props.cancellation(rowSelect)
    setRowSelect(undefined)
  }

  const columnsName = [
    {
      selector: row => row.id,
      name: 'Id',
    },
    {
      selector: row => row.person_name,
      name: 'Proveedor',
    },
    {
      selector: row => setDatetime(row.date),
      name: 'Fecha compra',
    },
    {
      selector: row => row.no_document,
      name: 'No. Comprobante',
    },
    {
      selector: row => <div className={row.status === 'Anulado' ? 'text-red-500': ''}>{row.status}</div>,
      name: 'Estado',
    },
    {
      selector: row => setMoney(row.total),
      name: 'total',
    },
    {
      selector: row => row.id,
      name: 'Acciones',
      cell: row => <ActionTableConn
        row={row}
        showCancellation={showCancellation}
        viewDetail={viewDetail}/>
    }
  ]


  const viewDetail = (row) => {
    setCurrentRow(row)
    setIdModal(1)
  }

  const resetInfoModal = (_idModal) => {
    setIdModal(_idModal)
    setCurrentRow({})
    setItem({})
  }

  return (
    <ContainerComponent
      uri={'crear'}
      title="Compras"
      textBtnCreate={'Registar Compra'}
    >

      <BillModal
        show={idModal === 1}
        row={currentRow}
        closeModal={resetInfoModal}
        {...props}
      />

      <DialogOfDeleteComponent
        setShow={setShowModal}
        show={showModalCancellation}
        title={"Cancelar compra"}
        message={"Estás a punto de anular una compra. Esta acción no se puede deshacer. ¿Estás seguro de que deseas continuar?"}
        confirmCallBack={confirmCancellation}
      />

      <TableWithStripedRows
        {...props}
        dontShowAction={true}
        columns={columnsName}
        actionDelete={props.deleteRecord}
        searchChange={props.searchChange}
        searchValue={props.search}
      />
    </ContainerComponent>
  )

}

const ActionTable = ({row, showCancellation, viewDetail, user}) => {
  const [hasPermission, setPermission] = useState(false);
  const [hasPermissionCancel, setPermissionCancel] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location && user && row) {
      if (user.views === 'is_superuser') {
        setPermission(true)
        setPermissionCancel(!(row.status === 'Anulado'))
      } else {
        if (user.views?.length) {
          user.views.forEach((view) => {
            if (view.view_f === location.pathname && view.read) {
              setPermission(true)
            }
            if (view.view_f === location.pathname && view.edit) {
              setPermissionCancel(!(row.status === 'Anulado'))
            }
          })
        }
      }
    }
  }, [user, row]);


  return (
    <div className="flex">
      <IconButton variant="text" className={`py-0 my-0 text-amber-600 ${!hasPermission && 'hidden'}`}
                  onClick={() => viewDetail(row)}>
        <ClipboardDocumentCheckIcon className="w-6"/>
      </IconButton>
      <IconButton variant="text" className={`py-0 my-0 text-red-600 ${!hasPermissionCancel && 'hidden'}`}
                  onClick={() => showCancellation(row)}>
        <NoSymbolIcon className="w-6"/>
      </IconButton>
    </div>
  )

}

const States = (state) => {
  return {
    user: state.user.data
  };
};

const ActionTableConn = connect(States, undefined)(ActionTable);