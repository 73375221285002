import {connect} from 'react-redux';
import {InventoryList} from "./List/InventoryList";
import {actions} from '../../js/redux/inventory';

const inventoryState = (state) => {
  return {
    ...state.inventory,
    user: state.user.data,
  };
};


const InventoryListConn = connect(inventoryState, actions)(InventoryList);

export {
  InventoryListConn,
}