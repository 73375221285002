import {
  Card,
  Input,
  Button,
  Typography,
} from "@material-tailwind/react";

import {Formik, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
import {required} from "../../../components/elements/Forms/validations";
import {LoaderRippleComponent} from "../../../components/elements/Loader/LoaderComponent";
import {TITLES} from "../../../config/texts";


export function Step1(props) {

  const ResetPassSchema = Yup.object().shape({
    email: required().email('Correo invalido'),
  });

  return (
    <div className="grid 2xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 xl:grid-cols-8 grid-cols-12 gap-2 h-full">
      <div
        className="col-start-2 col-span-10 2xl:col-start-5 2xl:col-span-2 lg:col-start-4 lg:col-span-2 md:col-start-3 md:col-span-4 row-start-1 my-auto">
        <Card color="white" className="flex text-center xl:pt-14 lg:pt-14 md:pt-14 py-4 md:pb-10 lg:pb-10 xg:pb-10">
          <LoaderRippleComponent loader={props.loader}/>
          <Typography variant="h4" color="blue-gray">
            {TITLES.APP_NAME}
          </Typography>
          <Typography color="gray" className="mt-1 text-sm w-10/12 mx-auto" variant="lead">
            Ingresa el correo electrónico de tu cuenta y te enviaremos un código de verificación.
          </Typography>
          <Formik
            initialValues={{email: ''}}
            onSubmit={(values) => {
              props.login(values)
            }}
            validationSchema={ResetPassSchema}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
              <Form
                className="my-8 mb-2 w-10/12 mx-auto text-left"
              >
                <div className="mb-1 flex flex-col gap-6">
                  <div className="w-full">
                    <Input
                      type="email"
                      label="Correo"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!(errors.username && touched.username)}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="ml-2 text-red-500 text-[10px] font-normal absolute"/>
                  </div>
                </div>

                <Button
                  fullWidth
                  color="gray"
                  type="submit"
                  variant="filled"
                  className="mt-6 bg-blue-gray-50"
                >
                  Enviar código
                </Button>

                <div className="mt-2 text-end w-full">
                  <a href="/login" className="font-medium text-gray-900 text-sm">
                    Iniciar sesión
                  </a>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </div>
  );
}
