import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {required} from "../../../components/elements/Forms/validations";
import {FormComponent} from "../../../components/elements/Forms/FormComponent";
import ContainerComponent from "../../../components/elements/ContainerComponent";


export function CreateCustomer(props) {
  const {isView = false, isUpdate = false, item, retrieve, loader} = props;
  const [didMount, setMount] = useState(false)
  const nav = useNavigate();
  const {id} = useParams();
  
  useEffect(() => {
    setMount(true)
  }, []);
  
  useEffect(() => {
    if (id && didMount){
      retrieve(id);
    }
  }, [id, didMount, retrieve]);


  
  const fields = {
    nit: {label: 'NIT', maxLength: '13', type: 'number'},
    first_name: {label: 'Nombres', validation: required()},
    second_last_name: {label: 'Apellidos'},
    address: {label: 'Dirección'},
    phone_number: {label: 'Teléfono', type: 'number', maxLength: '8'},
    email: {label: 'Correo Electrónico'},
  }

  const handleSubmit = (dataForm) => {
    dataForm.person && delete dataForm.person;
    let data = {
      person: {...dataForm},
      notification: dataForm.notification,
      branch_office: dataForm.branch_office,
    }
    delete data.person.notification;
    delete data.person.branch_office;
    if(id){
      props.update(id, data, nav)
    } else {
      props.create(data, nav)
    }
  }

  return (
    <>
      <ContainerComponent
        title={isUpdate ? "Editar Cliente" : isView? `Cliente ${id}` : "Crear Cliente"}
      >
        <FormComponent
          isUpdate={isUpdate}
          isView={isView}
          fields={fields}
          loader={loader}
          data={item? {...item, ...item.person}: {}}
          actionSubmit={handleSubmit}
          pathBack={'/cliente'}
        />
      </ContainerComponent>
    </>
  )
}