import {configureStore} from '@reduxjs/toolkit'
import {thunk} from "redux-thunk"
import user from './auth'
import users from './user'
import product from './products/products'
import customer from './customer/customer'
import category from './products/categories'
import branchOffice from './branchOffices/branchOffices'
import role from './role/role'
import cashRegiter from './cashRegister'
import cashRegisterAssignation from './cashRegisterAssignation'
import sales from './sales'
import inventory from './inventory'
import proveedores from './Proveedores/Proveedores'
import compra from "./compra/compra";
import ventaCredito from "./ventaCredito/ventaCredito";
import warehouse from "./warehouse/warehouse";

export default configureStore({
  reducer: {
    user,
    sales,
    users,
    category,
    product,
    customer,
    role,
    inventory,
    branchOffice,
    cashRegiter,
    cashRegisterAssignation,
    proveedores,
    compra,
    ventaCredito,
    warehouse,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})