import {useEffect, useState} from "react";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";

export function CustomerList(props) {
  const [didMount, setDidMount] = useState(false);
  const {list} = props;

  useEffect(() => {
    setDidMount(true);
  }, []);
  
  useEffect(() => {
    if(didMount){
      list();
    }
  }, [didMount, list]);

  const columnsName = [
    {
      selector: row => row.id,
      name: 'Id',
    },
    {
      selector: row => row.person.nit,
      name: 'NIT',
    },
    {
      selector: row => row.person.names,
      name: 'Nombres',
    },
    {
      selector: row => row.person.last_names,
      name: 'Apellidos',
    },
    {
      selector: row => row.person.phone_number,
      name: 'Teléfono',
    },
    {
      selector: row => row.person.email,
      name: 'Correo Electrónico',
    },
  ]

  return (
    <ContainerComponent
      uri={'crear'}
      title="Clientes"
      textBtnCreate={'Crear Clientes'}
    >
      <TableWithStripedRows
        {...props}
        columns={columnsName}
        actionDelete={props.deleteRecord}
        searchChange={props.searchChange}
        searchValue={props.search}
      />
    </ContainerComponent>
  )

}