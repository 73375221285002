import React, {useEffect, useState} from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix, Button, Accordion, AccordionHeader, AccordionBody,
} from "@material-tailwind/react";

import {
  XMarkIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import {useNavigate, useLocation} from "react-router-dom";
import Menu from "./Menu";

export function SidebarComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenus, setOpenMenus] = useState({});
  const [didMount, setMount] = useState(false);
  const [menuData, setMenu] = useState([]);
  const {data} = props;

  useEffect(() => {
    setMount(true)
    renderViews()
  }, []);

  const renderViews = ()=>{
    const views = localStorage.getItem('views')
    if (views === 'is_superuser') {
      setMenu(Menu)
    } else {
      const menu = [];
      const jsonViews = JSON.parse(views)
      // recorremos el array de vistas que tiene el usuario
      jsonViews?.forEach(({view_f}) => {
        // Recoremos el array de Menu para validar cuales se activan
        Menu.forEach((item) => {
          // Si no tiene hijos se compara si los paths coinciden para agregarlo al nuevo menu
          if (item._path === view_f && !item.children) {
            menu.push(item)
          } else if (item.children) {
            // Si tiene hijos se compara si los paths de los hijos coinciden con el item de la vista que se esta evaluando
            // y si es asi se agrega a un nuevo array de hijos para este item
            let child = []
            item.children.forEach((ele)=>{
              if(ele._path === view_f){
                child.push(ele)
              }
            })
            if(child.length){
              const newItem = {
                title : item.title,
                icon : item.icon,
                children : child,
              }
              const buttonChildren = menu.find((ele)=> ele.title === newItem.title)
              if(buttonChildren){
                buttonChildren.children = [...buttonChildren.children, ...child]
              } else {
                menu.push(newItem)
              }
            }
          }
        })
      })
      setMenu(menu)
    }
  }

  useEffect(()=>{
    renderViews()
  }, [didMount, data])

  const handleOpenMenu = (index) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  }

  const action = (url) => {
    if (url) navigate(url)
    hiddenSidebar()
  }
  const hiddenSidebar = () => {
    const sidebarContainer = document.getElementById('sidebarContainer')
    sidebarContainer.classList.add('hidden')
    sidebarContainer.classList.remove('absolute')
    sidebarContainer.classList.remove('z-20')
    document.body.classList.remove('overscroll-none')
    document.body.classList.remove('overflow-hidden')
  }

  let validateClassName = (item) => {
    const reg = /\/detalle\/+[0-9]/g;
    const reg2 = /\/crear/g;
    const reg3 = /\/editar\/+[0-9]/g;
    let rutaActual = location.pathname.replace(reg, '')
    rutaActual = rutaActual.replace(reg2, '')
    rutaActual = rutaActual.replace(reg3, '')

    if(item?.children) {
      let classItemAcordion = 'p-0'
      item.children.forEach((child)=>{
        if (child._path === rutaActual) {
          classItemAcordion = 'p-0 bg-blue-gray-100/90'
        }
      })
      return classItemAcordion
    }
    return rutaActual === item._path ? 'bg-blue-gray-100/90 text-blue-gray-900' : 'text-blue-gray-900'
  };

  return (
    <Card
      id="sidebarContainer"
      className="lg:h-[calc(100vh-0.2rem)] lg:w-full lg:max-w-[17rem] py-4 shadow-xl shadow-blue-gray-900/5 hidden lg:flex md:fixed lg:fixed  w-screen h-screen overflow-auto">
      <div className="mb-2 p-4 flex flex-row justify-between">
        <Typography variant="h6" color="blue-gray">
          Agro Servicios el Esfuerzo
        </Typography>
        <Button type={"button"} onClick={hiddenSidebar} className="lg:hidden md:hidden p-2 bg-transparent text-black" variant="filled">
          <XMarkIcon className="w-6 h-6"/>
        </Button>
      </div>

      <List>
        {menuData.map((item, index) =>
          // Si item tiene children entonces se muestra el boton de abrir menu como un acordeon
          item.children ?
            <Accordion
              key={index}
              open={openMenus[index] ?? false}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${openMenus[index] ? "rotate-180" : ""}`}
                />
              }>
              <ListItem className={validateClassName(menuData[index])} selected={openMenus[index]}>
                <AccordionHeader onClick={() => {
                  handleOpenMenu(index)
                }} className="border-b-0 p-3">
                  <ListItemPrefix>
                    {item.icon}
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal">
                    {item.title}
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <List className="p-0">
                  {item.children.map((child, index) =>
                    <ListItem
                      key={index}
                      onClick={() => action(child._path)}
                      className={validateClassName(child)}>
                      <ListItemPrefix className="ml-4 mr-2"> {child.icon} </ListItemPrefix>
                      {child.title}
                    </ListItem>
                  )}
                </List>
              </AccordionBody>
            </Accordion>
            :
            <ListItem
              key={index}
              onClick={() => action(item._path)}
              className={validateClassName(item)}>
              <ListItemPrefix className="mr-2"> {item.icon} </ListItemPrefix>
              {item.title}
            </ListItem>
        )}
      </List>
    </Card>
  );
}