import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Card, Typography} from "@material-tailwind/react";
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {CardStatistics} from "./CardStatistics";
import {BanknotesIcon, ShoppingCartIcon, UserGroupIcon, ShoppingBagIcon} from '@heroicons/react/24/solid'
import {RecentOrders} from "./RecentOrders";
import {useLocation} from "react-router-dom";
import {TITLES} from "../../config/texts";

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function DashboardComponent(props) {
  const [didMount, setMount] = useState(false);
  const [hasPermission, setPermission] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (didMount) validatePermission(true)
  }, [didMount])

  const validatePermission = () => {
    if (location) {
      const views = localStorage.getItem('views')
      if (views === 'is_superuser') {
        setPermission(true)
      } else if (views) {
        const jsonView = JSON.parse(views)
        jsonView.forEach((view) => {
          if (location.pathname === view.view_f && view.create) {
            setPermission(true)
          }
        })
      }
    }
  }


  return (
    <>
      {
        (hasPermission) ?
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 gap-6 px-6 lg:px-0">
              <CardStatistics
                title="Total ventas"
                percentage="Q 5,000.00"
                icon={<BanknotesIcon className="w-5 h-5"/>}
                legend="+20.1% del mes pasado"
              />
              <CardStatistics
                title="Total productos vendidos"
                percentage="200"
                icon={<ShoppingCartIcon className="w-5 h-5"/>}
                legend="+20.1% del mes pasado"
              />
              <CardStatistics
                title="Total compras"
                percentage="Q 2,000.00"
                icon={<ShoppingBagIcon className="w-5 h-5"/>}
                legend="registrado el ultimo mes"
              />
              <CardStatistics
                title="Clientes nuevos"
                percentage="10"
                icon={<UserGroupIcon className="w-5 h-5"/>}
                legend="+1.1% del mes pasado"
              />
              <div className="col-span-1 lg:col-span-2">
                <ITEMCARD/>
              </div>
              <div className="col-span-1 lg:col-span-2">
                <RecentOrders/>
              </div>
            </div>
            <Typography variant="h2" className="opacity-10 text-center mt-4"> {TITLES.APP_NAME}</Typography>
          </div>
          :
          <div className="p-5">
            <Typography variant="h1"> Bienvenido a tu espacio de trabajo </Typography>
            <Typography variant="small"> En la parte izquierda encontralas las vistas a las cuales tienes acceso</Typography>
            <div className="min-h-[40vh] h-full flex flex-row justify-center align-middle">
              <Typography variant="h2" className="opacity-10 text-center my-auto"> {TITLES.APP_NAME}</Typography>
            </div>
          </div>
      }
    </>
  )
}


const ITEMCARD = () => {
  return (
    <Card className="w-full h-[500px] border rounded-none py-4 pr-4">
      <Typography variant="h4" color="black" className="mx-auto">
        Ventas
      </Typography>
      <div className="w-full h-full">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{r: 8}}/>
            <Line type="monotone" dataKey="uv" stroke="#82ca9d"/>
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  )
}


const StatesRedux = (state) => {
  return {
    user: state.user.data,
  };
};


const DashboardComponentConn = connect(StatesRedux, undefined)(DashboardComponent);

export {
  DashboardComponentConn,
}