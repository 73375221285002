import {Typography} from "@material-tailwind/react";
import {useEffect, useState} from "react";
import Lottie from "lottie-web";
import animation from '../Lottie/animationLoader2'


export const LoaderRippleComponent = ({loader}) => {
  const [classLoader, setClassLoader] = useState('left-0 right-0 bottom-0 absolute h-full bg-black/30 z-10 flex justify-center items-center hidden');
  useEffect(() => {
    if (loader) {
      setClassLoader('left-0 right-0 bottom-0 absolute h-full bg-black/30 z-10 flex justify-center items-center')
    } else {
      setClassLoader('left-0 right-0 bottom-0 absolute h-full bg-black/30 z-10 flex justify-center items-center hidden')
    }
  }, [loader])

  return (
    <div
      className={classLoader}
    >
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
}


export const LoaderTextComponent = () => {
  return (
    <div className="left-0 right-0 bottom-0 absolute h-full bg-black/30 z-10 flex justify-center items-center">
      <Typography variant="h4" className="animated-text" color="white">
        Cargando ...
      </Typography>
    </div>
  )
}

export const LoaderEllipsisComponent = ({loader}) => {
  const [classLoader, setClassLoader] = useState('left-0 right-0 bottom-0 absolute h-full z-10 flex justify-center items-center hidden');
  useEffect(() => {
    if (loader) {
      setClassLoader('bg-white/50 left-0 right-0 bottom-0 absolute h-full z-10 flex justify-center items-center')
    } else {
      setClassLoader('left-0 right-0 bottom-0 absolute h-full z-10 flex justify-center items-center hidden')
    }
  }, [loader])


  return (
    <div className={classLoader}>
      <div
        // className="shadow-lg mt-10 bg-white w-[200px] h-[120px] justify-center content-center align-middle text-center"
      >
        <div className="lds-ellipsis black">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Typography className="-mt-7 text-center font-semibold" style={{font: '20px'}}>
          Cargando
        </Typography>
      </div>

    </div>
  )
}


export function DefaultSkeleton() {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: document.getElementById('lottie-container'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
    return () => animation.destroy();
  }, [animationData]);

  useEffect(() => {
    setAnimationData(animation)
  }, []);


  return (
    <div className="flex justify-center items-center w-full h-screen bg-[#f1f2f5]">
      <div className="w-[90vh] text-center flex flex-col" style={{marginTop: '-3rem'}}>
        <div id="lottie-container" className=""/>
        <Typography variant="h2" className="animated-text text-center w-full h-fit" color="black"
                    style={{marginTop: '-2.85rem'}}>
          Cargando ...
        </Typography>
      </div>
    </div>
  );
}