import {useEffect, useState} from "react";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";
import {WarehouseStatus} from "../../../utils/shared";

export function WarehouseList(props) {
  const [didMount, setDidMount] = useState(false);
  const {list} = props;

  useEffect(() => {
    setDidMount(true);
  }, []);
  
  useEffect(() => {
    if(didMount){
      list();
    }
  }, [didMount, list]);

  const columnsName = [
    {
      selector: row => row.id,
      name: 'Id',
    },
    {
      selector: row => row.name,
      name: 'Nombre',
    },
    {
      selector: row => row.branch_office?.name,
      name: 'Sucursal',
    },
    {
      selector: row => WarehouseStatus.find((item)=> item.value === row.status)?.label,
      name: 'Estado',
    },
  ]

  return (
    <ContainerComponent
      uri={'crear'}
      title="Bodegas"
      textBtnCreate={'Crear Bodega'}
    >
      <TableWithStripedRows
        {...props}
        columns={columnsName}
        actionDelete={props.deleteRecord}
        searchValue={props.search}
      />
    </ContainerComponent>
  )

}