import {Button, Card, CardBody} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";


export default function ErrorPageComponte() {
  const nav = useNavigate()


  return (
    <div className="w-full h-screen grid grid-cols-12 gap-4 md:grid-cols-12 lg:grid-cols-12 py-[3rem]">
      <Card className="col-start-3 col-span-8 h-[calc(100%-3rem)] col-end-11 text-center content-center ">
        <img alt="page not found" src="/assets/page-not-found.svg" className="h-full mx-auto sticky"/>
        <CardBody className="w-full p-3 absolute opacity-90 bottom-0">
          <div className="w-full">
            <Button
              variant="filled"
              color="blue"
              onClick={() => nav('/')}
              className="text-white px-6 mx-auto flex w-fit">
              <span>Volver a la pagina inicial</span>
            </Button>
          </div>
          <p className="text-lg text-center text-slate-50 mt-1">
            Lo sentimos, no podemos encontrar la página que estás buscando. 🚧
          </p>
        </CardBody>
      </Card>
    </div>
  );
}