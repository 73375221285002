import {connect} from 'react-redux'
import {actions} from '../../../js/redux/products/categories'
import {CategoriesListComponent} from './List/CategoriesList'
import {CreateCategory} from './Create/CreateCategory'

const categoryState = (state) => {
  return {
    ...state.category,
  };
};


const CategoriesListConn = connect(categoryState, actions)(CategoriesListComponent);
const CreateCategoriesConn = connect(categoryState, actions)(CreateCategory);


export {
  CategoriesListConn,
  CreateCategoriesConn,
}