import {connect} from 'react-redux';
import {RoleList} from "./List/RoleList";
import {CreateRole} from "./Create/CreateRole";
import {actions} from '../../js/redux/role/role';

const rolState = (state) => {
  return {
    ...state.role,
  };
};


const CreateRoleConn = connect(rolState, actions)(CreateRole);
const RoleListConn = connect(rolState, actions)(RoleList);

export {
  CreateRoleConn,
  RoleListConn,
}