import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {required} from "../../components/elements/Forms/validations";
import ContainerComponent from "../../components/elements/ContainerComponent";
import {FormComponent} from "../../components/elements/Forms/FormComponent";
import {Card, CardBody} from "@material-tailwind/react";

export const FormResetPassword = (props) => {
  const {isView = false, isUpdate = false, retrieve, data, loader} = props;
  const [didMount, setMount] = useState(false)
  const nav = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    setMount(true)
  }, []);

  useEffect(() => {
    if (id && didMount) {
      retrieve(id);
    }
  }, [id, didMount, retrieve]);


  const fields = {
    username: {label: 'Usuario', disabled: true},
    old_password: {label: 'Contraseña actual', validation: required(), type: 'password'},
    password: {label: 'Nueva contraseña', validation: required(), type: 'password'},
    confirm_password: {label: 'Confirmar nueva contraseña', validation: required(), type: 'password'},
  }

  const handleSubmit = (data) => {
    props.update(id, data, nav)
  }

  return (
    <div
      title="Cambiar contraseña"
    >
      <Card className=" w-full md:w-[60%] lg:w-[40%] xl:w-[40%] mx-auto flex flex-col">
        <CardBody>
          <FormComponent
            isUpdate={true}
            isView={isView}
            fields={fields}
            loader={loader}
            data={data}
            containerForm="p-4"
            divFormGroup="xl:grid-cols-1 2xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 !gap-2"
            actionSubmit={handleSubmit}
            pathBack={'/'}
          />
        </CardBody>

      </Card>
    </div>
  )
}