import {createReducer} from '../baseReducer';


export const {slice, extraActions} = createReducer(
  'cashRegisterAssignation',
  'cash-register-assignment',
  '/cajas'
);

// Action creators are generated for each case reducer function
export const actionsAssignation = {
  ...extraActions,
  ...slice.actions,
}

export default slice.reducer