import {
  Card,
  CardBody, CardFooter,
  List,
  ListItem,
  Typography
} from '@material-tailwind/react';
import {setMoney, setDatetime} from '../../../utils/shared';


export function BillFormat(props) {
  const {totalSales, warehouse, supplier, creation_date, purchase_detail, id, status, no_document, observations} = props;

  return (
    <div ref={props.componentRef} className='mx-auto' id='purchaseReceipt'>
      <Card className='border mx-auto gap-0 rounded-none border-black/20' id="subcontainerPurchaseReceipt">
        {status === 'Anulado' &&
          <div className="absolute bg-blue-gray-50/10 w-full h-full text-center content-center">
            <Typography variant="h1" className="opacity-20">ANULADO</Typography>
          </div>
        }
        <div className='pb-4 px-3 mt-5'>
          {/* ***************** HEADER BILL *****************  */}
          <div className="w-full flex flex-row justify-end">
            <Typography variant='h6' color='black' className="border py-1 px-2 border-black/20">
              Compra #: {id}
            </Typography>
          </div>
          <div className="w-full mt-2 grid grid-cols-12 grid-rows-2">
            <Typography variant='small' color='black' className="font-semibold col-span-3">
              Fecha Compra:
            </Typography>
            <Typography variant='small' color='black' className="col-span-9">
              {creation_date ? setDatetime(creation_date) : '-------'}
            </Typography>
            <Typography variant='small' color='black' className="font-semibold col-span-3">
              Estado:
            </Typography>
            <Typography variant='small' color='black' className="col-span-9">
              {status ?? '-------'}
            </Typography>
            <Typography variant='small' color='black' className="font-semibold col-span-3">
              No. comprobante:
            </Typography>
            <Typography variant='small' color='black' className="col-span-9">
              {no_document ?? "-------"}
            </Typography>
            <Typography variant='small' color='black' className="font-semibold col-span-3">
              Proveedor:
            </Typography>
            <Typography variant='small' color='black' className="col-span-9">
              {supplier?.person?.first_name ?? '-------'}
            </Typography>
          {
            warehouse?.name &&
            <>
              <Typography variant='small' color='black' className="font-semibold col-span-3">
                Bodega:
              </Typography>
              <Typography variant='small' color='black' className="font-semibold col-span-9">
                {warehouse.name}
              </Typography>
            </>
          }
          <div className='border-b border-dashed border-black w-[100%] col-span-7 mt-2'></div>
        </div>
    </div>
  <CardBody className='px-2 pt-0'>
    <List className='px-0 border p-0 border-black/20'>
      {/* ***************** HEADER PRODUCT *****************  */}
      <ListItem
        selected
        ripple={false}
        className="py-0 mb-0 border-b border-black/20 cursor-default rounded-none grid grid-cols-12 text-[14.5px] text-black">
        <div className='col-span-12 text-center font-semibold text-black/80'>
          Detalle de compra
        </div>
      </ListItem>
      <ListItem
        selected
        ripple={false}
        className="py-0 !bg-transparent  cursor-default  rounded-none grid grid-cols-12 text-[14.5px] text-black">
        <div className='col-span-7 border-b border-dashed border-black'>
          Descripcion
        </div>
        <div className='col-span-2 text-right '>
          <span className='border-b border-dashed border-black'>
          Precio
          </span>
        </div>
        <div className='col-span-3  text-right '>
          <span className='border-b border-dashed border-black'>
            Subtotal
          </span>
        </div>
      </ListItem>
      {/* ***************** PRODUCTS  *****************  */}
      {
        purchase_detail?.length ? purchase_detail.map((item) => (
            <ListItem
              selected={false}
              ripple={false}
              key={item.id}
              className="py-0 !bg-transparent gap-0 cursor-default rounded-none grid grid-cols-12 text-[12px] text-black">
              <div className='col-span-7'>
                {item.quantity} {" - " + item.name + ` (${item.presentation})`}
              </div>
              <div className='col-span-2 text-right'>
                {setMoney(item.price)}
              </div>
              <div className='col-span-3 text-right'>
                {setMoney(item.price * item.quantity)}
              </div>
            </ListItem>
          ))
          :
          <div className='w-full text-center'>
            <Typography variant='paragraph' color='blue-gray'>Ningún producto comprado</Typography>
          </div>
      }
      <ListItem
        selected={false}
        ripple={false}
        className='pt-2 !bg-transparent cursor-default pb-1 rounded-none grid grid-cols-12 text-[14.5px] text-black'>
        <strong className='col-end-13 col-span-5 text-black  text-right'>
          Total: {totalSales}
        </strong>
      </ListItem>
    </List>

  </CardBody>
  <CardFooter className="mt-0 pt-0 px-3">
    <Typography className={"pt-1"} variant="small" color="black">
      <span className="text-black font-bold">NOTA:</span> {observations}
    </Typography>
  </CardFooter>

</Card>
</div>

)
}
