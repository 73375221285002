import {connect} from 'react-redux';
import {PurchaseList} from "./List/PurchaseList";
import {actions} from '../../js/redux/compra/compra';
import {CreatePurchase} from "./Create/CreatePurchase";


const PurchaseState = (state) => {
  return {
    ...state.compra,
    user: state.user.data
  };
};


const CreatePurchaseConn = connect(PurchaseState, actions)(CreatePurchase);
const PurchaseListConn = connect(PurchaseState, actions)(PurchaseList);

export {
  CreatePurchaseConn,
  PurchaseListConn,
}