import {connect} from 'react-redux';
import {actions} from '../../../js/redux/auth';
import {ResetPassword} from './ResetPassword';

const ms2p = (state) => {
  return {
    ...state.auth,
  };
};

const md2p = {...actions};

export default connect(ms2p, md2p)(ResetPassword);