import {SidebarComponent} from "./Sidebar";
import {NavbarComponent} from "./Navbar";
import {useNavigate} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {connect} from 'react-redux';
import {actions} from '../../js/redux/auth';
import {DefaultSkeleton} from "../elements/Loader/LoaderComponent";
import {AnimationLayout} from "./AnimationLayout";


function LayoutComponent(props) {
  let navigate = useNavigate();
  const [didMount, setMount] =  useState(false)

  useEffect(()=>{
    setMount(true)
  }, [])

  useEffect(() => {
    if(!props.loaderMe && didMount){
      props.verifySession(navigate)
    }
  }, [props.loaderMe, didMount]);

  return (
    <>
      {
        (!didMount || props.loaderMe) ?
          <DefaultSkeleton/> :
          <Fragment>
            <SidebarComponent {...props} id="sidebar"></SidebarComponent>
            <NavbarComponent {...props} ></NavbarComponent>
            <div
              id="outlet"
              className="lg:w-[calc(100%-21rem)] lg:ml-[19rem] lg:mb-0 max-h-[calc(100vh-3rem)] pt-[5rem] md:pt-5 lg:pt-5 xl:pt-5 mx-5 lg:mx-0 md:mx-0">
              <div className="">
                <AnimationLayout/>
              </div>
            </div>
          </Fragment>
      }
    </>
  );
}


const states = (state) => {
  return {
    ...state.user,
  };
};


export default connect(states, actions)(LayoutComponent);