import {connect} from 'react-redux';
import {CustomerList} from "./List/CustomerList";
import {CreateCustomer} from "./Create/CreateCustomer";
import {actions} from '../../js/redux/customer/customer';

const customerState = (state) => {
  return {
    ...state.customer,
  };
};


const CreateCustomerConn = connect(customerState, actions)(CreateCustomer);
const CustomerListConn = connect(customerState, actions)(CustomerList);

export {
  CreateCustomerConn,
  CustomerListConn,
}