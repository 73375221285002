import {required} from "../../../../components/elements/Forms/validations";
import {FormComponent} from "../../../../components/elements/Forms/FormComponent";
import ContainerComponent from "../../../../components/elements/ContainerComponent";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";


export function CreateCategory(props) {
  const {isView = false, isUpdate = false, item, retrieve} = props;
  const [didMount, setMount] = useState(false)
  const nav = useNavigate();
  const {id} = useParams();

  
  useEffect(() => {
    if (id && didMount){
      retrieve(id);
    }
  }, [id, didMount, retrieve]);

  useEffect(() => {
    setMount(true)
  }, []);

  const fields = {
    description: {label: 'Descripción', validation: required()},
    color: {label: 'Color', type: 'color', validation: required()},
  }

  const handleSubmit = (data) => {
    if(id){
      props.update(id, data, nav)
    } else {
      props.create(data, nav)
    }
  }
  return (
    <>
      <ContainerComponent
        title={isUpdate ? "Editar categoría de producto" : isView? `Categoría ${id}` : "Crear Categoría de Productos"}
      >
        <FormComponent
          isUpdate={isUpdate}
          isView={isView}
          fields={fields}
          data={item}
          actionSubmit={handleSubmit}
          pathBack={'/producto/categoria'}
        />
      </ContainerComponent>
    </>
  )
}