import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {required} from "../../../components/elements/Forms/validations";
import {FormComponent} from "../../../components/elements/Forms/FormComponent";
import ContainerComponent from "../../../components/elements/ContainerComponent";


export function CreateUser(props) {
  const {isView = false, isUpdate = false, item, retrieve, loader} = props;
  const [didMount, setDidMount] = useState(false)
  const nav = useNavigate();
  const {id} = useParams();

  useEffect(()=>{
    setDidMount(true);
  }, [])

  useEffect(() => {
    if (id && didMount){
      retrieve(id);
    }
  }, [id, retrieve, didMount]);

  const fields = {
    // photo: {label: 'Photo', type: 'imagen'},
    branch_office: {label: 'Sucursal',  validation: required(), type: 'select', urlOptionList : 'branch-offices/select' },
    role: {label: 'Rol', validation: required(), type: 'select', urlOptionList: 'role/select_option'},
    username: {label: 'Usuario', validation: required()},
    nit: {label: 'NIT', maxLength: '13'},
    dpi: {label: 'DPI', maxLength: '13',  type: 'number'},
    first_name: {label: 'Primer Nombre', validation: required()},
    second_name: {label: 'Segundo Nombre'},
    third_name: {label: 'Tercer Nombre'},
    last_name: {label: 'Primer Apellido', validation: required()},
    second_last_name: {label: 'Segundo Apellido'},
    department: {label: 'Departmaneto', type: 'select', urlOptionList: 'department/select_option'},
    municipality: {label: 'Municipio', type: 'select', urlOptionList: 'municipality/select_option', dependence: 'department'},
    address: {label: 'Dirección', validation: required()},
    phone_number: {label: 'Teléfono', type: 'number', maxLength: '8'},
    email: {label: 'Correo Electrónico', validation: required().email('Debe ingresar un correo valido')},
    birth_date: {
      type: 'date',
      label: 'Fecha de nacimiento',
      maxdate: new Date().setHours(new Date().getHours()),
      mindate: new Date().setMonth(new Date().getMonth() - (12 * 96)),
  },
  }

  const handleSubmit = (dataForm) => {
    dataForm.person && delete dataForm.person;
    const data = {
      person : {
        ...dataForm,
      },
      branch_office: dataForm.branch_office,
      role: dataForm.role,
      username: dataForm.username,
    }
    delete data.person.branch_office;
    delete data.person.role;
    delete data.person.username;
    if(id){
      props.update(id, data, nav)
    } else {
      props.create(data, nav)
    }
  }
  return (
    <>
      <ContainerComponent
        title={isUpdate ? "Editar Usuario" : isView? `Usuario ${id}` : "Crear Usuario"}
      >
        <FormComponent
          isUpdate={isUpdate}
          isView={isView}
          fields={fields}
          loader={loader}
          data={item? {...item, ...item.person}: {}}
          actionSubmit={handleSubmit}
          pathBack={'/usuario'}
        />
      </ContainerComponent>
    </>
  )
}