import {Card, Typography} from "@material-tailwind/react";

export function CardStatistics({title, percentage, legend, icon}) {
  return (
    <Card className="p-4 min-h-[155px] rounded-none">
      {/* HEADER */}
      <div className="w-full py-2">
        <Typography className="text-black text-center" variant="h5">
          {title}
        </Typography>
        <div className="w-full justify-center text-black text-center flex">
          {icon}
        </div>
      </div>
      {/* BODY */}
      <div className="w-full py-2">
        <Typography className="text-black" variant="h4">
          {percentage}
        </Typography>
        <Typography variant="small">
          {legend}
        </Typography>
      </div>
    </Card>
  )
}