import React from "react";
import {Button, Typography} from "@material-tailwind/react";
import {BUTTON_CREATE} from "../../../config/componentsVariant";


export function PrimaryButton(props) {
  return (
      <Button
        type={props.type ?? "submit"}
        onClick={props?.onClick}
        disabled={props.disabled}
      >
        <Typography color="inherit" variant="small" className={BUTTON_CREATE.CLASS_TEXT}>
          &nbsp;{props.text}
        </Typography>
      </Button>
  );
}

export function SecondaryButton(props) {
  return (
      <Button
        type="button"
        variant="outlined"
        color="blue-gray"
        onClick={props.onClick}
        htmlFor="goBackButton"
      >
        <Typography color="blue-gray" variant="small" className={BUTTON_CREATE.CLASS_TEXT}>
          &nbsp;{props.text}
        </Typography>
      </Button>
  );
}

export function TheerdButton(props) {
  return (
      <Button
        type="button"
        variant="outlined"
        onClick={props.onClick}
        htmlFor="goBackButton"
      >
        <span>&nbsp; {props.text} </span>
      </Button>
  );
}


