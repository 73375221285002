import {useState, useMemo, useEffect} from "react";
import {
  EyeIcon,
  PencilSquareIcon,
  MagnifyingGlassIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  IconButton,
  CardHeader,
  CardBody,
  Input,
  Button, Typography, Tooltip,
} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import {LoaderEllipsisComponent} from "../Loader/LoaderComponent";
import {DialogOfDeleteComponent} from "../Dialogs/DialogComponent";
import DataTable from 'react-data-table-component';
import {useLocation} from 'react-router-dom';
import {connect} from "react-redux";
import {ROWS_PER_PAGE} from "../../../utils/shared";
import {SecondTrashIcon} from "../../../modules/Products/Create/FormPresentation";

export const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      backgroundColor: 'rgba(225,225,225,0.4)',
      height: '35px',
    },
  },
}

export function NoDataComponent({columns}) {
  return (
    <div className="w-full min-w-max table-auto text-left py-0 ">
      <table className="w-full min-w-max table-auto text-left h-[35px]">
        <thead>
        <tr>
          {columns.map((head) => (
            <th
              key={head.name}
              style={{backgroundColor: tableCustomStyles.headCells.style.backgroundColor}}
              className="border-b border-blue-gray-100 px-4 py-1"
            >
              <Typography
                variant="small"
                color="blue-gray"
                className="font-bold h-[20px]"
              >
                {head.name}
              </Typography>
            </th>
          ))}
        </tr>
        </thead>
      </table>
      <div>
        <Typography variant="h6" className="text-center mt-2">
          Sin datos
        </Typography>
      </div>
    </div>
  )
}

function TableWithStripedRowsComponent({data = [], columns = [], page = 1, ...props}) {
  const {loader = false, actionDelete, user} = props;
  const [show, setShow] = useState(false);
  const [record, setItem] = useState(false);
  const [didMount, setMount] = useState(false);
  const [columnsFinal, setColumns] = useState(columns);
  const nav = useNavigate();
  const [hasPermission, setPermission] = useState(false)
  const [hasPermissionEdit, setPermissionEdit] = useState(false)
  const location = useLocation();
  const {results, count} = data

  useEffect(() => {
    setMount(true)
    validatePermission()
  }, [])


  useEffect(() => {
    validatePermission()
  }, [location, user, didMount]);

  const validatePermission = () => {
    if (location) {
      const views = localStorage.getItem('views')
      if (views === 'is_superuser') {
        setPermission(true)
        setPermissionEdit(true)
      } else {
        if (views) {
          const jsonViews = JSON.parse(views)
          jsonViews.forEach((ele) => {
            if (ele.view_f === location.pathname && ele.read) {
              setPermission(true)
            }
            if (ele.view_f === location.pathname && ele.edit) {
              setPermissionEdit(true)
            }
          })
        }
      }
    }
  }

  useEffect(() => {
    if (didMount && !props.dontShowAction) {
      const columnsWithAction = [
        ...columns,
        {
          selector: row => row.id,
          name: 'Acciones',
          cell: row => <div className="flex">
            <IconButton
              variant="text"
              className="py-0 my-0 text-black/60 hover:text-indigo-400 hover:bg-gray-100"
              onClick={() => nav(`${props.newRoute + 'detalle/' + row.id}`.replace('undefined', ''))}>
              <EyeIcon className="w-5 h-5 text-indigo-400"/>
            </IconButton>
            {hasPermissionEdit &&
              <>
                <IconButton
                  variant="text"
                  className="py-0 my-0 text-black/60 hover:text-amber-600 hover:bg-gray-100"
                  onClick={() => nav(`${props.newRoute + 'editar/' + row.id}`.replace('undefined', ''))}
                >
                  <PencilSquareIcon className="w-5 h-5 text-amber-600"/>
                </IconButton>
                <IconButton
                  onClick={() => openModalDelete(row)}
                  variant="text"
                  className="hover:text-red-500 text-black/60 py-0 my-0 flex justify-center items-center hover:bg-gray-100">
                  <SecondTrashIcon className="w-5 h-5 text-red-500">
                  </SecondTrashIcon>
                </IconButton>
              </>
            }
          </div>
        }
      ]
      setColumns(columnsWithAction)
    }
  }, [didMount, columns, nav, props.newRoute]);


  const openModalDelete = (item) => {
    setShow(true)
    setItem(item)
  }

  const confirmCallBack = () => {
    actionDelete && actionDelete(record.id)
  }

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }


  const actionsMemo = useMemo(() => <ExportButtonExcel onExport={() => downloadCSV(results)}/>, [downloadCSV, results]);

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos',
  };

  return (
    <div className={hasPermission ? props.className ?? "" : 'hidden'}>
      <DialogOfDeleteComponent
        show={show}
        setShow={setShow}
        confirmCallBack={confirmCallBack}
      />
      <Card
        className={"h-full w-full overflow-x-scroll md:overflow-auto lg:overflow-hidden shadow-sm " + props.classNameCard}>
        <CardHeader
          className="p-2 pb-4 gap-4 grid grid-col-1 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 mx-0 shadow-none"
          floated={false}
          shadow={false}
        >
          <InputWithButton
            onClickSearch={props.searchChange}
            searchValue={props.searchValue}
          >
          </InputWithButton>
          <div className="col-span-1">
          </div>
          <div className="col-span-1">
          </div>
        </CardHeader>
        <CardBody className="px-4">
          <LoaderEllipsisComponent loader={loader}/>
          <DataTable
            pagination
            columns={columnsFinal}
            data={results}
            dense
            paginationPerPage={ROWS_PER_PAGE}
            paginationDefaultPage={1}
            persistTableHead={false}
            customStyles={tableCustomStyles}
            actions={actionsMemo}
            paginationServer={true}
            paginationTotalRows={count}
            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
              if (didMount) {
                props.ChangeRowsPerPage(currentRowsPerPage)
              }
            }}
            onChangePage={(page, totalRows) => {
              if (didMount) props.list(page);
            }}
            noDataComponent={<NoDataComponent columns={columns}/>}
            paginationComponentOptions={paginationComponentOptions}
          />
        </CardBody>
      </Card>
    </div>

  );
}

function InputWithButton(props) {
  const {onClickSearch, searchValue} = props;
  const [search, setSearch] = useState("");
  const [mounted, setMount] = useState();
  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      setSearch(searchValue)
    }
  }, [mounted])

  const onChange = ({target}) => {
    if (target.value === '') {
      onClickSearch('')
    }
    setSearch(target.value)
  };

  return (
    <div className="relative flex w-full max-w-[24rem]">
      <Input
        type="text"
        value={search}
        placeholder="Buscar"
        autoComplete="off"
        onChange={onChange}
        className="pr-24"
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onClickSearch(search)
          }
        }}
        containerProps={{
          className: "min-w-0",
        }}
      />
      <Tooltip content="Presiona para buscar" placement="top">
        <Button
          size="sm"
          onClick={event => onClickSearch(search)}
          color={search ? "gray" : "gray"}
          disabled={!search}
          className={`!absolute right-[1px] top-3-button rounded-none px-4 py-1.5 ${search && 'bg-blue-gray-100'}`}
        >
          {/*<PaperAirplaneIcon className={`w-4 h-4 rotate-[315deg] ${search ? '' : 'opacity-50'}`}></PaperAirplaneIcon>*/}
          <MagnifyingGlassIcon className={`w-5 h-5 ${search ? '' : 'opacity-50'}`}></MagnifyingGlassIcon>
        </Button>
      </Tooltip>

    </div>
  );
}

const ExportButtonExcel = ({onExport}) => {
  return (
    <IconButton color="gray" onClick={e => onExport(e.target.value)}>
      <img alt="excel" src="/assets/excel.png" className="w-5 h-5 text-green-500"/>
    </IconButton>
  )
};

const states = (state) => {
  return {
    user: state.user.data,
  };
};
const TableWithStripedRows = connect(states, undefined)(TableWithStripedRowsComponent);
export {TableWithStripedRows}