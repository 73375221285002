import {connect} from 'react-redux';
import {VentasCreditoList} from "./List/VentasCreditoList";
import {VentaCredito} from "./Create/VentaCredito";
import {actions} from '../../js/redux/ventaCredito/ventaCredito';

const CreditoState = (state) => {
  return {
    ...state.proveedores,
    user: state.user,
  };
};


const CrearCredito = connect(CreditoState, actions)(VentaCredito);
const ListarCreditos = connect(CreditoState, actions)(VentasCreditoList);

export {
  CrearCredito,
  ListarCreditos,
}