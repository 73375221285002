import {toast} from 'react-toastify';

const _optionToas = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "colored",
}

export const toastSuccess = (data) => {
  if (typeof data == 'object' && data?.status) {
    const validateMessage = validateStatus(data)
    return toast.success(validateMessage, _optionToas)
  } else {
    return toast.success(data, _optionToas)
  }
}

export function toastError(data) {
  if (typeof (data) == 'object') {
    const message = data.message;
    if (message && message.includes('timeout')) {
      return toast.error('El servidor tardo en responder', _optionToas)
    } else if (data.response) {
      const axiosError = data.response;
      const validateMessage = validateStatus(axiosError)
      return toast.error(validateMessage, _optionToas)
    }
  }
  return toast.error(data, _optionToas)
}

export function toastInfo(message) {
  return toast.info(message, _optionToas)
}

function validateStatus(response) {
  let finalMessage = '';
  switch (response.status) {
    case 200:
      finalMessage = 'Acción realizada exitosamente';
      break;
    case 201:
      finalMessage = 'Registro creado exitosamente';
      break;
    case 400:
      finalMessage = response.data.detail ?? 'Ruta no encontrada';
      break;
    case 401:
      finalMessage = response.data.detail ?? 'Ruta no autorizada';
      break;
    case 402:
      finalMessage = 'Requiere pago';
      break;
    case 403:
      finalMessage = response.data.detail ?? 'Credenciales vencidas o invalidas';
      break;
    case 405:
      finalMessage = 'Metodo no encontrado';
      break;
    case 500:
      finalMessage = 'Error en el servidor, intenta más tarde';
      break;
    default:
      break;
  }
  return finalMessage;
}