import {required} from "../../../components/elements/Forms/validations";
import {FormComponent} from "../../../components/elements/Forms/FormComponent";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {useNavigate} from "react-router-dom";
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";
import {setDateInput, setDatetime} from "../../../utils/shared";


export function CashRegisterClosing(props) {
  const {isUpdate = false, user} = props;
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript empiezan desde 0
  const year = date.getFullYear();
  console.log('user', user)
  const nav = useNavigate();
  const fields = {
    opening_date: {label: 'Fecha Apertura', type: 'date', disabled: true},
    closing_date: {label: 'Fecha Cierre', type: 'date', disabled: true},
    user: {
      label: 'Usuario / Cajero',
      type: 'select',
      maxLength: '12',
      disabled: true,
      options: [{value: user?.id, label: user?.username}]
    },
    branch_office: {
      label: 'Sucursal',
      urlOptionList: 'branch-offices/select',
      type: 'select',
      validation: required(),
      disabled: true
    },
    initial_amount: {
      label: 'Saldo Inicial',
      type: 'number',
      maxLength: '10',
      disabled: true
    },
    final_amount: {
      label: 'Saldo Final',
      validation: required().min(1, 'El campo debe tener mínimo 1 dígitos'),
      type: 'number',
      maxLength: '10'
    },
  }

  const initialState = {
    ...user?.cash_register,
    closing_date: user?.cash_register.closing_date ? setDateInput(new Date()): null,
    opening_date: user?.cash_register.opening_date ? setDatetime(new Date(user.cash_register.opening_date)):null,
  }

  const handleSubmit = (data) => {
    console.log('data', data)
    props.update(data.id, data, nav)
  }

  return (
    <>
      <ContainerComponent
        title={"Cierre de caja"}>
        <LoaderEllipsisComponent loader={!user} />
        {
          !!user?.cash_register &&
          <FormComponent
            isUpdate={isUpdate}
            isView={false}
            fields={fields}
            data={initialState}
            actionSubmit={handleSubmit}
            pathBack={'/venta/crear'}
          />
        }
      </ContainerComponent>
    </>
  )
}