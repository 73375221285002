const TITLES = {
  APP_NAME: 'Agroservicios El Esfuerzo',
  NIT_BILL: '148797654',
  ADDRESS_BILL: '8va. avenida 8-15 Zona 4',
  ListProduct: '',
  CreateProduct: '',
  UpdateProduct: '',
}

const BUTTONS = {
  ListProduct: '',
  CreateProduct: '',
  UpdateProduct: '',
}

export {
  TITLES,
  BUTTONS,
}