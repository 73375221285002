import {connect} from 'react-redux';
import {ProfileComponent} from "./ProfileComponent";
import {actions} from '../../js/redux/auth';

const productState = (state) => {
  return {
    ...state.user,
  };
};


const ProfileConn = connect(productState, actions)(ProfileComponent);

export {
  ProfileConn,
}