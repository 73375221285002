import React from 'react';
import './App.css';
import {theme} from "./theme/theme";
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom/client';
import router from './js/routes/routes';
import {RouterProvider} from "react-router-dom";
import {ThemeProvider} from "@material-tailwind/react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import store from './js/redux/store'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider value={theme}>
      <ToastContainer />
        <RouterProvider router={router}/>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
